import React from "react"

const SignSvg = (props) => (
    <span className="anticon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            {...props}
        >
            <g clipPath="url(#a)">
                <path
                    fill="#BDBDBD"
                    d="M15.5 15.75H.5a.25.25 0 0 1 0-.5h15a.25.25 0 0 1 0 .5Z"
                />
                <path
                    fill="#1B78D4"
                    d="M4.408 10.593c-.559-.559-1.289-.862-1.984-.838-.672.946-1.338 3.614-1.417 3.936a.25.25 0 0 0 .303.302c.321-.08 2.984-.744 3.933-1.415.024-.697-.278-1.428-.835-1.985Z"
                />
                <path
                    fill="#1B78D4"
                    d="M14.159.841A2.005 2.005 0 0 0 12.732.25c-.539 0-1.045.21-1.426.591L2.87 9.275c.681.092 1.356.43 1.89.964.533.534.87 1.209.961 1.892l8.437-8.436c.381-.381.591-.888.591-1.427 0-.54-.21-1.046-.591-1.427Z"
                />
                <path
                    fill="#181918"
                    d="m13.832 4.729-.354.353 1.168 1.168-2.823 2.823a.25.25 0 1 0 .354.354l3-3a.25.25 0 0 0 0-.354L13.832 4.73Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    </span>
)
export default SignSvg