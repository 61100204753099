import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  DatePicker,
  Button,
  InputNumber,
} from "antd";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableBanLeCTList from "./TableBanLeCTList";
import { PrinterOutlined } from "@ant-design/icons";

import {
  createBanHang,
  getBanHangByMabanhang,
  getBanHangCTByMabanhang,
  setBanHangCTList,
  updateBanHang,
} from "store/slices/sale/banhangSlice";

import {
  getDmDoiTuong,
  getDmThuocVatTu,
  getDmPhai,
} from "store/slices/sale/danhmucSlice";

import Utils from "utils";

import { DOCUMENT_TYPE_ID } from "constants";
import moment from "moment";
import { guidEmpty } from "constants";
import { initBanHangCt } from ".";
import SlipModalComponent from "components/SlipModalComponent";
// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
const formater = new Intl.NumberFormat("vi-VN");
// formatter and parser input number
export const formatterNumber = (val) => {
  if (!val) return 0;
  return formater.format(val);
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")
  ).toFixed(5);
};
export default function ModalBanLe({
  visibleModal,
  setVisibleModal,
  loading,
  banHangDetail,
  setBanHangId,
  banHangId,
  reloadData,
  banHangCTList = [],
  permitList = [],
  setMaKkxuat,
  maKkxuat,
  setNgayxuat,
  ngayxuat,
  setMadoituong,
  madoituong,
  setIdPhieu,
  idPhieu,
  openNotification,
  dmKkList,
  setIsPrinter,
  setPrintData,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const childRef = useRef(null);
  const { dmDoiTuongList, dmThuocVatTuList, dmPhaiList } = useSelector(
    (state) => state.danhMucSale
  );
  useEffect(() => {
    dispatch(getDmDoiTuong());
    dispatch(getDmThuocVatTu());
    dispatch(getDmPhai());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (banHangId === null && idPhieu === null) {
      return;
    }
    dispatch(
      getBanHangByMabanhang({
        id: banHangId ?? 0,
        idphieu: idPhieu ?? guidEmpty,
        onSuccess: (data) => {
          dispatch(
            getBanHangCTByMabanhang({
              mabanhang: data?.mabanhang ?? 0,
              isNewCt: false,
            })
          );
        },
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, banHangId, idPhieu]);
  useEffect(() => {
    if (banHangId === null) {
      form.setFieldValue("ngay", moment());
      return;
    }
    form.setFieldsValue(banHangDetail);
    form.setFieldValue("ngay", moment(banHangDetail.ngay));
  }, [form, banHangDetail, banHangId, dispatch]);

  const onFinish = (values) => {
    const payload = {
      sophieu: values.sophieu,
      makk: values.makk,
      loaiphieu: 0,
      madoituong: values.madoituong,
      ngay: values.ngay,
      maphai: values.maphai,
      hoten: values.hoten,
      masothue: values.masothue,
      sotaikhoan: values.sotaikhoan,
      daduyet: values.daduyet,
      manvduyet: values.manvduyet,
      manv: values.manv,
      ghichu: values.ghichu,
      dienthoai: values.dienthoai,
      diachi: values.diachi,
      onSuccess: ({ data }) => {
        if (banHangId === null || banHangId === 0) {
          openNotification("success", "topRight", `Thêm phiếu thành công!`);
          if (data.Id && data.MaPhieu && childRef.current) {
            childRef.current.signedDocumentCreate(data.Id, data.MaPhieu);
          }
          setBanHangId(data.MaBanHang);
        } else {
          openNotification("success", "topRight", `Lưu phiếu thành công!`);
        }

        setMaKkxuat(values.makk);
        setNgayxuat(values.ngay);
        setMadoituong(values.madoituong);
        reloadData();
      },
    };

    if (banHangId !== null) {
      payload.mabanhang = banHangId;
      dispatch(updateBanHang(payload));
    } else {
      dispatch(createBanHang(payload));
    }
  };
  const renderTabs = useMemo(
    () => [
      {
        label: "Thông tin chi tiêt",
        children: (
          <TableBanLeCTList
            setBanHangId={setBanHangId}
            banHangCTList={banHangCTList}
            dmThuocVatTuList={dmThuocVatTuList}
            permitList={permitList}
            banHangId={banHangId}
            maKkxuat={maKkxuat}
            ngayxuat={ngayxuat}
            madoituong={madoituong}
          />
        ),
      },
    ],
    [
      banHangCTList,
      banHangId,
      dmThuocVatTuList,
      maKkxuat,
      madoituong,
      ngayxuat,
      permitList,
      setBanHangId,
    ]
  );
  const renderInfo = () => {
    return (
      <Spin tip="Đang tải..." spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish} className="p-3">
          <Row gutter={24}>
            <Col sm={8} md={8} lg={7}>
              <Form.Item name="sophieu" label="Số phiếu">
                <Input placeholder="Tự động" readOnly />
              </Form.Item>
            </Col>{" "}
            <Col sm={8} md={8} lg={9}>
              <Form.Item
                name="ngay"
                label="Ngày bán"
                rules={[
                  {
                    required: true,
                    message: "Ngày lập phiếu là bắt buộc",
                  },
                ]}
              >
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  placeholder={"Ngày bán"}
                  format="DD/MM/YYYY HH:mm"
                />
              </Form.Item>
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Form.Item
                name="madoituong"
                label="Đối tượng"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn đối tượng",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  options={dmDoiTuongList}
                  placeholder="Chọn đối tượng"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={24} lg={24}>
              <Form.Item
                name="makk"
                label="Kho xuất"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn kho xuất",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  options={dmKkList?.filter((x) => x.maloai === 1)}
                  placeholder="Chọn kho xuất"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={16} md={16} lg={16}>
              <Form.Item
                name="hoten"
                label="Khách hàng"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên khách hàng",
                  },
                ]}
              >
                <Input
                  placeholder="Nhập tên khách hàng"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Form.Item
                name="dienthoai"
                label="SĐT"
                rules={[Utils.validatePhoneNumberVN]}
              >
                <Input placeholder="Nhập số điện thoại" allowClear={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="ngaysinh" label="Ngày sinh">
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  placeholder={"Ngày sinh"}
                />
              </Form.Item>
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="maphai" label="Giới tính">
                <Select
                  showSearch
                  filterOption={filterOption}
                  options={dmPhaiList}
                  placeholder="Chọn giới tính"
                />
              </Form.Item>
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="diachi" label="Địa chỉ">
                <Input placeholder="Nhập địa chỉ" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24} md={24} lg={24}>
              <Form.Item name="ghichu" label="Ghi chú">
                <Input placeholder="Nhập ghi chú" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={8} md={8} lg={8}>
              <Form.Item name="congno" label="Công nợ">
                <InputNumber
                  style={{ width: "100%" }}
                  step={1000}
                  formatter={(value) => formatterNumber(value)}
                  parser={(value) => parserNumber(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                Lưu
              </Button>
            </Col>
            <Col sm={12}>
              <Button
                title={"In phiếu"}
                type="primary"
                onClick={() => {
                  if (banHangId === null || banHangId === 0) {
                    openNotification(
                      "error",
                      "topRight",
                      `Phiếu không tồn tại!`
                    );
                    return;
                  }
                  setIsPrinter(true);
                  setPrintData({
                    idphieu: banHangDetail.idphieu,
                    mabanhang: banHangDetail.mabanhang,
                    sophieu: banHangDetail.sophieu,
                  });
                }}
                icon={<PrinterOutlined type="primary" />}
              >
                In
              </Button>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={24} lg={0}>
              <Form.Item name="daduyet" label="Đã duyệt" hidden>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col sm={24} md={24} lg={0}>
              <Form.Item name="manvduyet" label="Mã nhân viên duyệt" hidden>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  };
  return (
    <SlipModalComponent
      ref={childRef}
      maskClosable={false}
      bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      width={1400}
      title={
        banHangId === null || banHangId === 0
          ? "Thêm phiếu bán lẻ"
          : "Sửa phiếu bán lẻ"
      }
      objectId={banHangDetail?.idphieu}
      objectType={"PhieuBanLe"}
      widthInfo={550}
      documentTypeId={DOCUMENT_TYPE_ID.BanLe}
      titleTLink="Bán lẻ"
      descriptionTLink={banHangDetail?.banHangId}
      isVisibleModal={visibleModal}
      onRenderTab={renderTabs}
      onRenderInfo={() => renderInfo()}
      onSignReloadForm={(data) => {
        if (data.totalSigned === data.totalSigns) {
          dispatch(
            getBanHangByMabanhang({
              id: banHangId ?? 0,
              idphieu: idPhieu ?? guidEmpty,
            })
          );
          reloadData();
        }
      }}
      onCancelModal={() => {
        setVisibleModal(false);
        form.resetFields();
        setBanHangId(null);
        setIdPhieu(null);
        dispatch(setBanHangCTList([initBanHangCt]));
        reloadData();
      }}
    />
  );
}
