import axiosInstance from "auth/FetchInterceptor";
import { XUATKHO_API } from "constants/ApiConstant";
const XuatKhoService = {};

XuatKhoService.getXuatKhoCTByManx = function (data) {
  return axiosInstance.post(`${XUATKHO_API}/get-ct-by-manx`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
XuatKhoService.addXuatKhoCT = function (data) {
  return axiosInstance.post(`${XUATKHO_API}/add-xuatkhoct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
XuatKhoService.updateXuatKhoCT = function (data) {
  return axiosInstance.post(`${XUATKHO_API}/update-xuatkhoct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
XuatKhoService.deleteXuatKhoCT = function (data) {
  return axiosInstance.post(`${XUATKHO_API}/delete-xuatkhoct`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

XuatKhoService.getTonXuatKho = function (data) {
  return axiosInstance.post(`${XUATKHO_API}/get-ton-xuat-kho`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
export default XuatKhoService;
