import { notification, Select } from "antd";

import React, { useEffect, useRef, useState } from "react";
import TableBanLeList from "./TableBanLeList";
import ModalBanLe from "./ModalBanLe";
import { useDispatch, useSelector } from "react-redux";
import { getDSBanHang } from "store/slices/sale/banhangSlice";
import moment from "moment";
import { SystemButtonSource } from "constants";
import MeditechTablePage, { FilterComponent } from "components/table-layout";
import DocumentViewer from "constants/DocumentViewer";
import { getDmKk } from "store/slices/sale/danhmucSlice";
const initSearch = {
  startDate: moment().startOf("month"), // moment().date(-60),
  endDate: moment().endOf("month"),
  searchText: null,
  loai: 0,
  makk: null,
};
export const initBanHangCt = {
  action: "initial",
  isRequired: true,
};
const BanLeList = ({ queryId, openModalDetail }) => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);
  const { permitList } = useSelector((state) => state.auth);
  const [banHangId, setBanHangId] = useState(null);
  const [maKkxuat, setMaKkxuat] = useState(null);
  const [ngayxuat, setNgayxuat] = useState(null);
  const [madoituong, setMadoituong] = useState(null);
  const [idPhieu, setIdPhieu] = useState(null);
  const [isprinter, setIsPrinter] = useState(false);
  const [printData, setPrintData] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, placement, message) => {
    api[type]({
      message: message,
      placement,
    });
  };
  const searchFormRef = useRef({ ...initSearch });
  const [searchFormData, setSearchFormData] = useState(searchFormRef.current);
  const { banHangList, loading, banHangDetail } = useSelector(
    (state) => state.banHangSale
  );
  const { dmKkList } = useSelector((state) => state.danhMucSale);
  const { branchId } = useSelector((state) => state.auth);
  const { banHangCTList } = useSelector((state) => state.banHangSale);
  useEffect(() => {
    dispatch(getDmKk());
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, branchId]);

  useEffect(() => {
    if (openModalDetail) {
      setVisibleModal(openModalDetail);
      setIdPhieu(queryId);
    }
  }, [queryId, openModalDetail]);

  const reloadData = () => {
    dispatch(getDSBanHang(searchFormRef.current));
  };
  const searchHandle = (dataSearch) => {
    searchFormRef.current = { ...searchFormRef.current, ...dataSearch };
    setSearchFormData({ ...searchFormRef.current });
    reloadData();
  };
  const onRenderSearchView = () => {
    return (
      <FilterComponent
        datasource={searchFormData}
        onSearchImmediate={searchHandle}
        renderInputSearch
        renderDatePicker
      >
        <Select
          key="makk"
          data-field="makk"
          style={{ width: "100%", minWidth: 280 }}
          placeholder="Kho xuất"
          options={dmKkList?.filter((x) => x.maloai === 1)}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </FilterComponent>
    );
  };

  const onDeleteHandler = (id) => {};

  const onOpenDetailHandler = (id) => {
    //setDoanKhamId(id);
    setVisibleModal(true);
  };

  const getRightButton = (isLocked) => {
    const arr = [];
    if (isLocked) {
      arr.push({
        keyName: SystemButtonSource.addBtn,
        title: "Thêm",
        showMore: false,
        // disable: !allowAddNew,
      });
    }
    return arr;
  };

  const onRightTopBtnHandler = (key) => {
    switch (key) {
      case SystemButtonSource.addBtn:
        setVisibleModal((prev) => !prev);
        break;
      default:
        break;
    }
  };
  return (
    <>
      {contextHolder}
      <MeditechTablePage
        tableTitle="Bán lẻ"
        btnHeaderRightSource={getRightButton(true)}
        btnHeaderRightSourceHandle={onRightTopBtnHandler}
        onRenderSearchView={onRenderSearchView}
      >
        <TableBanLeList
          loading={loading}
          setBanHangId={setBanHangId}
          banHangList={banHangList}
          setVisibleModal={setVisibleModal}
          visibleModal={visibleModal}
          permitList={permitList}
          setMaKkxuat={setMaKkxuat}
          setNgayxuat={setNgayxuat}
          setMadoituong={setMadoituong}
          reloadData={reloadData}
          onOpenDetail={onOpenDetailHandler}
          onDelete={onDeleteHandler}
          setIsPrinter={setIsPrinter}
          setPrintData={setPrintData}
        />
      </MeditechTablePage>

      <ModalBanLe
        visibleModal={visibleModal}
        loading={false}
        setVisibleModal={setVisibleModal}
        setBanHangId={setBanHangId}
        banHangId={banHangId}
        banHangDetail={banHangDetail}
        reloadData={() => reloadData()}
        permitList={permitList}
        banHangCTList={banHangCTList}
        setMaKkxuat={setMaKkxuat}
        maKkxuat={maKkxuat}
        setNgayxuat={setNgayxuat}
        ngayxuat={ngayxuat}
        setMadoituong={setMadoituong}
        madoituong={madoituong}
        setIdPhieu={setIdPhieu}
        idPhieu={idPhieu}
        openNotification={openNotification}
        dmKkList={dmKkList}
        setIsPrinter={setIsPrinter}
        setPrintData={setPrintData}
      />
      <DocumentViewer
        openModel={isprinter}
        setIsPrinter={setIsPrinter}
        soPhieu={printData.sophieu}
        idPhieu={printData.idphieu}
        setPrintData={setPrintData}
        param={{
          mabanhang: (printData.mabanhang || 0).toString(),
          ct: "2",
        }}
        tenMauIn={"BanHang.rptPhieuBanLe"}
        loaiPhieu={"BanHang"}
        tieuDe={`In phiếu bán lẻ ${printData.sophieu}`}
      />
    </>
  );
};

export default BanLeList;
