import axiosInstance from "auth/FetchInterceptor";
import {
  GET_ALL_HOSPITAL_API,
  GET_BY_DEPARTMENT_API,
  GET_BY_DEPARTMENT_LEVEL_API,
  GET_BY_HIS_DEPARTMENT_API,
  GET_BY_JOB_POSITION_API,
  GET_BY_JOB_SPECCIALIZE_API,
  GET_BY_JOB_TITLE_API,
  GET_BY_WORK_STATUS_API,
  GET_DEAPARTMENT_BY_BRANCH,
  GET_SPECALIZE_BY_BRANCH,
  GET_TIME_SLOT,
  GET_ALL_BAGET_API,
  GET_PROVINCE_API,
  GET_DISTRICT_BY_PROVINCE_API,
  GET_WARD_BY_DISTRICT_API,
  GET_ALL_NATION_API,
  GET_LOAI_CA_LAM_VIEC_CATEGORY_API,
  GET_SYMBOL_CATEGORY_API,
  GET_Reason_CATEGORY_API,
  GET_ALL_EMPLOYEE_API,
} from "constants/ApiConstant";
const CategoryService = {};

CategoryService.getByJobPosition = function (data) {
  return axiosInstance.get(GET_BY_JOB_POSITION_API, null);
};
CategoryService.getByJobSpecialize = function (data) {
  return axiosInstance.get(GET_BY_JOB_SPECCIALIZE_API, null);
};
CategoryService.getByJobTitle = function (data) {
  return axiosInstance.get(GET_BY_JOB_TITLE_API, null);
};
CategoryService.getByDepartment = function (branchId) {
  return axiosInstance.get(`${GET_BY_DEPARTMENT_API}/${branchId}`, null);
};
CategoryService.getByWorkStatus = function (data) {
  return axiosInstance.get(GET_BY_WORK_STATUS_API, null);
};
CategoryService.getByHisDepartment = function (branchId) {
  return axiosInstance.get(GET_BY_HIS_DEPARTMENT_API, null);
};
CategoryService.getByDepartmentLevel = function (data) {
  return axiosInstance.get(GET_BY_DEPARTMENT_LEVEL_API, null);
};
CategoryService.getDepartmentByBranch = function (id) {
  return axiosInstance.get(`${GET_DEAPARTMENT_BY_BRANCH}/${id}`, null);
};
CategoryService.getAllSymbol = function () {
  return axiosInstance.get(GET_SYMBOL_CATEGORY_API);
};
CategoryService.getAllReason = function () {
  return axiosInstance.get(GET_Reason_CATEGORY_API);
};
CategoryService.getSpecialityAPI = function () {
  return axiosInstance.get(GET_SPECALIZE_BY_BRANCH, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
CategoryService.getTimeSlotAPI = function () {
  return axiosInstance.get(GET_TIME_SLOT, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CategoryService.getHospitalApi = function () {
  return axiosInstance.get(GET_ALL_HOSPITAL_API, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CategoryService.getBagetApi = function () {
  return axiosInstance.get(GET_ALL_BAGET_API, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CategoryService.getProvinceApi = function () {
  return axiosInstance.get(GET_PROVINCE_API, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CategoryService.getDistrictApi = function (provinceId) {
  return axiosInstance.get(
    `${GET_DISTRICT_BY_PROVINCE_API}?provinceId=${provinceId}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

CategoryService.getWardApi = function (districtId) {
  return axiosInstance.get(
    `${GET_WARD_BY_DISTRICT_API}?districtId=${districtId}`,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};

CategoryService.getNationApi = function () {
  return axiosInstance.get(GET_ALL_NATION_API, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

//QUAN LY CONG
CategoryService.getAllLoaiCalamViec = function () {
  return axiosInstance.get(`${GET_LOAI_CA_LAM_VIEC_CATEGORY_API}`, null);
};

CategoryService.getAllEmployee = function (isSubEmployee) {
  return axiosInstance.get(
    `${GET_ALL_EMPLOYEE_API}?isSubEmployee=${isSubEmployee}`,
    null
  );
};

export default CategoryService;
