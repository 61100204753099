import React from "react"

const DownloadSvg = (props) => (
    <span className="anticon">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_130_10003)">
                <path d="M12 5V16" stroke="#219737" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.5 11.5L12 16L16.5 11.5" stroke="#219737" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.5 20H19.5" stroke="#219737" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_130_10003">
                    <rect width="16" height="16" fill="white" transform="translate(4 4.5)" />
                </clipPath>
            </defs>
        </svg>

    </span>
)
export default DownloadSvg