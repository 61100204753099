import {
  GET_HOSO_API,
  GET_HOSO_BY_ID_API,
  UPDATE_HOSO_API,
  CREATE_HOSO_API,
  DELETE_HOSO_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const hoSoService = {};


hoSoService.GetManage = function (data) {
  return axiosInstance.post(`${GET_HOSO_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
hoSoService.GetManageById = function (data) {
  return axiosInstance.get(`${GET_HOSO_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
hoSoService.UpdateManage = function (data) {
  return axiosInstance.put(`${UPDATE_HOSO_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
hoSoService.CreateManage = function (data) {
  return axiosInstance.post(`${CREATE_HOSO_API}`, data, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};
hoSoService.DeleteManage = function (data) {
  return axiosInstance.delete(`${DELETE_HOSO_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
  });
};

export default hoSoService;
