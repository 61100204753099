import axiosInstance from "auth/FetchInterceptor";

const {
  GET_THONG_BAO_API,
  READALL_THONG_BAO_API,
  DELETEBYID_THONG_BAO_API,
  MARKREAD_THONG_BAO_API,
} = require("constants/ApiConstant");

const ErpNotificationService = {};

ErpNotificationService.search = function (formData) {
  return axiosInstance.post(GET_THONG_BAO_API, formData, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    ContentType: "multipart/form-data",
  });
};

ErpNotificationService.ReadAll = function () {
  return axiosInstance.get(READALL_THONG_BAO_API, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    ContentType: "multipart/form-data",
  });
};
ErpNotificationService.Delete = function (data) {
  return axiosInstance.delete(`${DELETEBYID_THONG_BAO_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    ContentType: "multipart/form-data",
  });
};
ErpNotificationService.Read = function (data) {
  return axiosInstance.get(`${MARKREAD_THONG_BAO_API}/${data}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    ContentType: "multipart/form-data",
  });
};
export default ErpNotificationService;
