import { Button, Input, InputNumber, Popconfirm, Table } from "antd";

import React, { useMemo, useState } from "react";
import { styled } from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Utils from "utils";
import { permitKey, PermitValue } from "constants";
import {
  createDmLoaiDv,
  getAllDmLoaiDv,
  setAllDmLoaiDvList,
  updateDmLoaiDv,
  deleteDmLoaiDv,
} from "store/slices/sale/danhmucSlice";

import { initDmLoaiDv } from "views/app-views/sale/category/DmLoaiDichVu";
const formater = new Intl.NumberFormat("vi-VN");
// formatter and parser input number
export const formatterNumber = (val) => {
  if (!val) return 0;
  return formater.format(val);
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
  ).toFixed(5);
};
const TableDmloaidvList = ({
  allDmLoaiDvList,
  reloadData,
  permitList = [],
}) => {
  const [editingKey, setEditingKey] = useState("");
  const dispatch = useDispatch();

  const allowLock = Utils.checkPermitValue(
    PermitValue.khoa,
    permitList,
    permitKey.crm_hospital
  );
  const allowEdit = Utils.checkPermitValue(
    PermitValue.sua,
    permitList,
    permitKey.crm_hospital
  );
  const allowDelete = Utils.checkPermitValue(
    PermitValue.xoa,
    permitList,
    permitKey.crm_hospital
  );

  const onChangeText = (type, record) => (e) => {
    const newData = allDmLoaiDvList.slice(0);
    const value = e?.target?.value ?? e;
    const index1 = newData.findIndex(
      (item) => item.maloaidichvu === record.maloaidichvu
    );
    newData[index1] = { ...newData[index1], [type]: value };
    dispatch(setAllDmLoaiDvList(newData));
  };
  const onClickAction = (record, index) => {
    const newData = allDmLoaiDvList.slice(0);
    const payload = {
      maloaidichvu: record.maloaidichvu,
      tenloaidichvu: record.tenloaidichvu,
      viettat: record.viettat,
      sapxep: record.sapxep,
      matldv: record.matldv,
      vat: record.vat,
      chuoinoidau: record.chuoinoidau,
      khongsudung: record.khongsudung,

      onSuccess: () => {
        dispatch(getAllDmLoaiDv());
      },
    };
    if (record.action === initDmLoaiDv.action) {
      dispatch(createDmLoaiDv(payload));
    } else {
      if (record.maloaidichvu !== null && record.action !== "update") {
        const index1 = newData.findIndex(
          (item) => item.maloaidichvu === record.maloaidichvu
        );
        newData[index1] = { ...newData[index1], action: "update" };
        dispatch(setAllDmLoaiDvList(newData));
      } else {
        dispatch(updateDmLoaiDv(payload));
      }
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        width: 50,
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Mã số",
        width: 90,
        dataIndex: "maloaidichvu",
      },
      {
        title: "Tên",
        width: 180,
        dataIndex: "tenloaidichvu",
        render: (_, record, index) =>
          record.action ? (
            <Input
              style={{ width: "100%" }}
              value={record.tenloaidichvu}
              placeholder="Nhập tên..."
              onChange={onChangeText("tenloaidichvu", record)}
            />
          ) : (
            record.tenloaidichvu
          ),
      },
      {
        title: "Viết tắt",
        width: 100,
        dataIndex: "viettat",
        render: (_, record, index) =>
          record.action ? (
            <Input
              style={{ width: "100%" }}
              value={record.viettat}
              placeholder="Tên viết tắt..."
              onChange={onChangeText("viettat", record)}
            />
          ) : (
            record.viettat
          ),
      },
      {
        title: "Sắp xếp",
        width: 80,
        dataIndex: "sapxep",
        render: (_, record, index) =>
          record.action ? (
            <InputNumber
              style={{ width: "100%" }}
              step={1}
              value={record.sapxep}
              placeholder="Nhập thứ tự"
              formatter={(value) => formatterNumber(value)}
              parser={(value) => parserNumber(value)}
              onChange={onChangeText("sapxep", record)}
            />
          ) : (
            record.sapxep
          ),
      },
      {
        fixed: "right",
        width: 90,
        align: "center",
        dataIndex: "action",
        render: (_, record, index) => (
          <>
            {record.action === "initial" ? (
              <Button
                //disabled={!record.tenhh || !allowView(PermitValue.them)}
                title={"Thêm thông tin"}
                onClick={() => onClickAction(record, index)}
                className="mr-2"
                icon={<PlusOutlined />}
                shape="circle"
              />
            ) : (
              <>
                <Button
                  disabled={
                    editingKey !== "" && editingKey !== record.maloaidichvu
                  }
                  title={"Sửa thông tin"}
                  //disabled={!allowView(PermitValue.sua)}
                  onClick={() => {
                    setEditingKey(editingKey !== "" ? "" : record.maloaidichvu);
                    onClickAction(record, index);
                  }}
                  className="mr-2"
                  icon={
                    record.action === "update" ? (
                      <SaveOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  shape="circle"
                />
                <Popconfirm
                  title="Bạn có muốn xóa không?"
                  placement="topLeft"
                  onConfirm={() => {
                    dispatch(
                      deleteDmLoaiDv({
                        id: record.maloaidichvu,
                        onSuccess: (r) => {
                          dispatch(getAllDmLoaiDv());
                        },
                      })
                    );
                  }}
                >
                  <Button
                    disabled={editingKey !== ""}
                    title={"Xóa thông tin"}
                    //disabled={!allowView(PermitValue.xoa)}
                    className="mr-2"
                    icon={<DeleteOutlined type="primary" />}
                    shape="circle"
                  />
                </Popconfirm>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allDmLoaiDvList]
  );

  return (
    <CustomTable
      columns={tableColumns}
      dataSource={allDmLoaiDvList}
      rowKey={(item) => item?.maloaidichvu}
      bordered
      style={{ padding: "0px" }}
      fixed={true}
      scroll={{ x: "max-content" }}
    />
  );
};

const CustomTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 2px;
  }
`;

export default TableDmloaidvList;
