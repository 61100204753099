import React from "react"

const RemoveSvg = (props) => (
    <span className="anticon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={12}
            fill="none"
            {...props}
        >
            <path
                stroke="#F31D00"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m1.5 1.5 9 9M10.5 1.5l-9 9"
            />
        </svg>
    </span>
)
export default RemoveSvg