import { Button, Col, DatePicker, Input, Row } from "antd";
import Flex from "components/shared-components/Flex";
import { isEmpty } from "lodash";
import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";

export const initSearch = {
  startDate: null,
  endDate: null,
  searchText: null,
};

export default function FilterBar({
  CustomComponent,
  loading,
  filter,
  setFilter,
  showButton = false,
  showDate = true,
  ButtonName = "Thêm",
  onHandle,
  showSearch = true,
}) {
  const onChangeSearch = (text) => {
    setFilter({ ...filter, searchText: text });
  };

  const handleChangeDatePicker = (value) => {
    let startDate = "";
    let endDate = "";
    if (!isEmpty(value)) {
      startDate = value[0].toISOString();
      endDate = value[1].toISOString();
    } else {
      startDate = null;
      endDate = null;
    }
    setFilter({ ...filter, endDate, startDate });
  };

  return (
    <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
      <Row gutter={12}>
        {showSearch && (
          <Col className="mb-1">
            <Input.Search
              placeholder="Tìm kiếm"
              onSearch={onChangeSearch}
              loading={loading}
            />
          </Col>
        )}
        {showDate && (
          <Col className="mb-1">
            <DatePicker.RangePicker onChange={handleChangeDatePicker} />
          </Col>
        )}
        {CustomComponent}
      </Row>
      <Flex>
        {showButton && (
          <Button
            onClick={onHandle}
            type="primary"
            className="mb-1"
            icon={<PlusCircleOutlined />}
            block
          >
            {ButtonName}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
