import axiosInstance from "auth/FetchInterceptor";
import { EMPLOYEE_TRANSFER_API } from "constants/ApiConstant";
const EmployeeTransferService = {};

EmployeeTransferService.searchGrid = function (data) {
  return axiosInstance.post(`${EMPLOYEE_TRANSFER_API}/search-grid`, data);
};

EmployeeTransferService.detail = function (id) {
  return axiosInstance.get(`${EMPLOYEE_TRANSFER_API}/detail?id=${id}`);
};

EmployeeTransferService.upSert = function (data) {
  return axiosInstance.post(`${EMPLOYEE_TRANSFER_API}/up-sert`, data);
};

EmployeeTransferService.updateEmployeeSync = function (id) {
  return axiosInstance.put(`${EMPLOYEE_TRANSFER_API}/employee-sync?id=${id}`);
};

EmployeeTransferService.delete = function (data) {
  return axiosInstance.delete(`${EMPLOYEE_TRANSFER_API}?id=${data.id}`);
};

export default EmployeeTransferService;
