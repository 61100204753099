import { Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import TableDmthuocvattuList from "./TableDmthuocvattuList";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDmdonvitinh,
  getAllDmLoaiDv,
  searchDmThuocVatTu,
} from "store/slices/sale/danhmucSlice";
import MeditechTablePage, { FilterComponent } from "components/table-layout";

const initSearch = {
  action: "initial",
  isRequired: true,
  timKiem: null,
  maLoaiDichVu: [],
};

export const initDmthuocvattu = {
  action: "initial",
  isRequired: true,
  timKiem: null,
  maLoaiDichVu: null,
};
const DmthuocvattuList = () => {
  const dispatch = useDispatch();
  const { permitList } = useSelector((state) => state.auth);
  const { searchDmThuocVatTuList, allDmLoaiDvList, loading } = useSelector(
    (state) => state.danhMucSale
  );

  useEffect(() => {
    dispatch(getAllDmdonvitinh({}));
    dispatch(getAllDmLoaiDv({}));
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const reloadData = () => {
    searchFormRef.current = { ...searchFormRef.current };
    dispatch(searchDmThuocVatTu(searchFormRef.current));
  };

  const searchFormRef = useRef({ ...initSearch });
  const [searchFormData, setSearchFormData] = useState(searchFormRef.current);
  const searchHandle = (dataSearch) => {
    searchFormRef.current = { ...searchFormRef.current, ...dataSearch };
    setSearchFormData({ ...searchFormRef.current });
    reloadData();
  };
  const onRenderSearchView = () => {
    return (
      <FilterComponent
        datasource={searchFormData}
        onSearchImmediate={searchHandle}
        // renderFilterMoreView={renderFilterMoreView}
        renderInputSearch
        renderDatePicker={false}
      >
        <Select
          key="maLoaiDichVu"
          data-field="maLoaiDichVu"
          style={{ width: "100%", minWidth: 200 }}
          placeholder="Loại dịch vụ"
          options={allDmLoaiDvList.map((m) => ({
            value: m.maloaidichvu,
            label: m.tenloaidichvu,
          }))}
          allowClear
          mode="multiple"
        />
      </FilterComponent>
    );
  };

  // const onDeleteHandler = (id) => {
  //   // dispatch(
  //   //   DeletePhuongAnKinhDoanh({
  //   //     id: id,
  //   //     onSuccess: ({ data }) => {
  //   //       if (data.isOk) {
  //   //         reloadData();
  //   //         notification.info({
  //   //           message: "Xóa phương án kinh doanh",
  //   //           description: "Xóa phiếu thành công",
  //   //         });
  //   //       } else {
  //   //         notification.error({
  //   //           message: "Xóa phương án kinh doanh",
  //   //           description: data.description,
  //   //         });
  //   //       }
  //   //     },
  //   //   })
  //   // );
  // };

  // const onOpenDetailHandler = (id) => {
  //   // setPhuongAnId(id);
  //   // setVisibleModal(true);
  // };

  const getRightButton = (isLocked) => {
    const arr = [];
    if (isLocked) {
      // arr.push({
      //   keyName: SystemButtonSource.addBtn,
      //   title: "Thêm",
      //   showMore: false,
      //   // disable: !allowAddNew,
      // });
    }
    return arr;
  };

  const onRightTopBtnHandler = (key) => {
    // switch (key) {
    //   case SystemButtonSource.addBtn:
    //     setVisibleModal((prev) => !prev);
    //     break;
    //   default:
    //     break;
    // }
  };

  return (
    <>
      <MeditechTablePage
        tableTitle="DANH MỤC THUỐC VẬT TƯ"
        btnHeaderRightSource={getRightButton(true)}
        btnHeaderRightSourceHandle={onRightTopBtnHandler}
        onRenderSearchView={onRenderSearchView}
      >
        <TableDmthuocvattuList
          loading={loading}
          allDmthuocvattuList={searchDmThuocVatTuList}
          reloadData={() => reloadData()}
          permitList={permitList}
        />
      </MeditechTablePage>
    </>
  );
};

export default DmthuocvattuList;
