import React, { useState } from 'react';
import { Button, Collapse } from 'antd';
import { SendSvg, RemoveSvg } from 'components/Icon';
import { SIGNATURE_STATUS } from 'constants';

const { Panel } = Collapse;

const Expandable = props => {
    const { signData, onRejectHandle, onRemindHandle } = props;
    const [isExpanded, setIsExpanded] = useState(true);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div className='d-flex align-items-center' style={{ marginBottom: 6, cursor: "pointer" }} onClick={toggleExpand}>
                {signData &&
                    signData.signaturePositionGroupByOrder && (<>
                        <div className='d-flex flex-fill' style={{ width: 'calc(100% - 280px)' }}>
                            {signData.signaturePositionGroupByOrder.map((item, idx) => {
                                return (
                                    <div key={idx}
                                        className='sign-group-step'
                                        style={{
                                            width: `${(item.totalSign / signData.totalSign) * 100}%`,
                                        }}
                                    >
                                        {item.signatureProcesses && item.signatureProcesses.map((sign, index) => {
                                            return (
                                                <div
                                                    key={`${index}-header-step`}
                                                    className={sign.signed === SIGNATURE_STATUS.Signed ? 'sign-step completed' : sign.isNextSign ? 'sign-step signing' : 'sign-step'}
                                                    style={{
                                                        width: `${100 / item.totalSign}%`,
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    <div className='step-title'>
                                                        {/* Tổng giám đốc công ty cổ phần tập đoàn medipha group */}
                                                        {sign.signName}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ width: 280, paddingLeft: 12 }}>
                            <div className='d-flex align-items-center justify-content-end border-left'>
                                <Button size='small' icon={<SendSvg />} onClick={(e) => { e.stopPropagation(); onRemindHandle() }}>Trình ký</Button>
                                <Button size='small' icon={<RemoveSvg />} onClick={(e) => { e.stopPropagation(); onRejectHandle(signData.signNameCurrent) }}>Từ chối ký</Button>
                            </div>
                        </div>
                    </>
                    )
                }
            </div>
            <Collapse activeKey={isExpanded ? '1' : ''} bordered={false}>
                <Panel key="1" showArrow={false} header="" forceRender>
                    {props.children}
                </Panel>
            </Collapse>
        </>
    );
}

export default Expandable;
