import axiosInstance from "auth/FetchInterceptor";
import { DANHGIAUNGVIEN_API, QUANLYDUAN_API } from "constants/ApiConstant";
const QuanLyDuAnService = {};

QuanLyDuAnService.GetAllDuAn = function (data) {
  return axiosInstance.post(
    `${QUANLYDUAN_API}/quan-ly-du-an/get-all-du-an`,
    data,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.GetAllDuAnUser = function (data) {
  return axiosInstance.get(
    `${QUANLYDUAN_API}/quan-ly-du-an/get-all-du-an-user?Id=${data}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.GetAllDuAnThanhVien = function (data) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/get-all-thanh-vien-du-an?BranchId=${data}`
  );
};
QuanLyDuAnService.GetAllDuAnBranch = function (data) {
  return axiosInstance.get(
    `${QUANLYDUAN_API}/quan-ly-du-an/get-all-du-an-branch?Id=${data}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};

QuanLyDuAnService.GetDuAn = function (id) {
  return axiosInstance.get(
    `${QUANLYDUAN_API}/quan-ly-du-an/get-du-an?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.XoaDuAn = function (id) {
  return axiosInstance.delete(
    `${QUANLYDUAN_API}/quan-ly-du-an/xoa-du-an?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.UpSertDuAn = function (data) {
  return axiosInstance.post(
    `${QUANLYDUAN_API}/quan-ly-du-an/up-sert-du-an`,
    data,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};

QuanLyDuAnService.DuyetDuAn = function (data) {
  return axiosInstance.post(
    `${QUANLYDUAN_API}/quan-ly-du-an/duyet-du-an`,
    data,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.GetAllCongViec = function (data) {
  return axiosInstance.post(
    `${QUANLYDUAN_API}/cong-viec/get-all-cong-viec`,
    data,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};

QuanLyDuAnService.GetCongViec = function (id) {
  return axiosInstance.get(
    `${QUANLYDUAN_API}/cong-viec/get-cong-viec?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};

QuanLyDuAnService.XoaCongViec = function (id) {
  return axiosInstance.delete(
    `${QUANLYDUAN_API}/cong-viec/xoa-cong-viec?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.UpSertCongViec = function (data) {
  return axiosInstance.post(
    `${QUANLYDUAN_API}/cong-viec/up-sert-cong-viec`,
    data,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.UpDownCongViec = function (data) {
  return axiosInstance.post(
    `${QUANLYDUAN_API}/cong-viec/up-down-cong-viec`,
    data,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.UpDownCongViecChiTiet = function (data) {
  return axiosInstance.post(
    `${QUANLYDUAN_API}/cong-viec/up-down-cong-viec-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.XoaCongViecChiTiet = function (data) {
  return axiosInstance.delete(
    `${QUANLYDUAN_API}/cong-viec/xoa-cong-viec-chi-tiet?Id=${data}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};

QuanLyDuAnService.GetAllCongViecChiTiet = function (boloc) {
  return axiosInstance.get(
    `${QUANLYDUAN_API}/cong-viec/get-all-cong-viec-chi-tiet?congViecId=${boloc.congViecId}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};

QuanLyDuAnService.GetAllCongViecChiTietByCV = function (list) {
  //CongViecList
  console.log("list", list);
  return axiosInstance.post(
    `${QUANLYDUAN_API}/cong-viec/get-all-cong-viec-chi-tiet-by-cv`,
    list,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};

QuanLyDuAnService.GetCongViecChiTiet = function (id) {
  return axiosInstance.get(
    `${QUANLYDUAN_API}/cong-viec/get-cong-viec-chi-tiet?Id=${id}`,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
QuanLyDuAnService.UpSertCongViecChiTiet = function (data) {
  return axiosInstance.post(
    `${QUANLYDUAN_API}/cong-viec/up-sert-cong-viec-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_PROJECT_ENDPOINT_URL,
    }
  );
};
export default QuanLyDuAnService;
