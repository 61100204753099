import {
  LIST_EMPLOYMENT_CONTRACT_API,
  TYPE_EMPLOYMENT_CONTRACT_API,
  SETTING_EMPLOYMENT_CONTRACT_API,
  HRM_EMPLOYMENT_CONTRACT_API,
  UPDATE_SETTING_CONTRACT_API,
  CREATE_TYPE_CONTRACT_API,
  UPDATE_TYPE_CONTRACT_API,
  DELETE_TYPE_CONTRACT_API,
  UPDATE_EMPLOYMENT_CONTRACT_API,
  DELETE_EMPLOYMENT_CONTRACT_API,
  CREATE_EMPLOYMENT_CONTRACT_API,
  GET_CONTRACT_BY_ID_API,
  STOP_EMPLOYMENT_CONTRACT_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const employmentContractService = {};

employmentContractService.GetSettingContract = function () {
  return axiosInstance.get(`${SETTING_EMPLOYMENT_CONTRACT_API}`);
};
employmentContractService.GetTypeContract = function () {
  return axiosInstance.get(`${TYPE_EMPLOYMENT_CONTRACT_API}`);
};
employmentContractService.GetHRMContract = function () {
  return axiosInstance.get(`${HRM_EMPLOYMENT_CONTRACT_API}`);
};
employmentContractService.UpdateSettingContract = function (data) {
  return axiosInstance.post(`${UPDATE_SETTING_CONTRACT_API}`, data);
};

employmentContractService.UpdateContractType = function (data) {
  return axiosInstance.put(`${UPDATE_TYPE_CONTRACT_API}`, data);
};
employmentContractService.CreateContractType = function (data) {
  return axiosInstance.post(`${CREATE_TYPE_CONTRACT_API}`, data);
};
employmentContractService.DeleteContractType = function (data) {
  return axiosInstance.delete(`${DELETE_TYPE_CONTRACT_API}?id=${data}`);
};

employmentContractService.GetContractList = function (data) {
  return axiosInstance.post(`${LIST_EMPLOYMENT_CONTRACT_API}`, data);
};
employmentContractService.UpdateContractList = function (data) {
  return axiosInstance.put(`${UPDATE_EMPLOYMENT_CONTRACT_API}`, data);
};
employmentContractService.DeleteContract = function (data) {
  return axiosInstance.delete(`${DELETE_EMPLOYMENT_CONTRACT_API}?id=${data}`);
};
employmentContractService.StopContract = function (data) {
  return axiosInstance.put(`${STOP_EMPLOYMENT_CONTRACT_API}`, data);
};

employmentContractService.CreateContractList = function (data) {
  return axiosInstance.post(`${CREATE_EMPLOYMENT_CONTRACT_API}`, data);
};

employmentContractService.GetContractById = function (data) {
  return axiosInstance.get(`${GET_CONTRACT_BY_ID_API}?id=${data}`);
};
export default employmentContractService;
