import { Button } from "antd";
import { DownloadSvg } from "components/Icon";

const MediActionDownload = (props) => {
    const handleClick = (e) => {
        if (props.onClick) {
            props.onClick(e);
        }
    }

    return (
        <Button {...props} icon={<DownloadSvg />} onClick={handleClick} type="text">
            {props.children}
        </Button>
    );
}

export default MediActionDownload;