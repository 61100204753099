import React from "react"

const SendSvg = (props) => (
    <span className="anticon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={16}
            fill="none"
            {...props}
        >
            <path
                stroke="#616161"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.004 9.503 13 15.5l3-15-15 6 2.002 1.501"
            />
            <path
                stroke="#616161"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.13 11.848 5 15.5l.004-5.997L9.5 6.5"
            />
        </svg>
    </span>
)
export default SendSvg