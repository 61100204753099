import React from "react"

const TrashSvg = (props) => (
    <span className="anticon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={17}
            fill="none"
            {...props}
        >
            <path
                stroke="#F31D00"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 6.5V15a1.5 1.5 0 0 0 1.5 1.5h8A1.5 1.5 0 0 0 14 15V6.5M1 4.5h15M6 4.5v-3h5v3M8.5 8.5v5M11 8.5v5M6 8.5v5"
            />
        </svg>
    </span>
)
export default TrashSvg