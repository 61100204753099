import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CdhaService from "services/CdhaService.js";

export const getCdhaById = createAsyncThunk(
  "ketquaCdha/getCdhaById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CdhaService.getCdhaById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getXetNghiemByCode = createAsyncThunk(
  "ketquaCdha/getXetNghiemByCode",
  async (data, { rejectWithValue }) => {
    try {
      const {payload, onSuccess} = data;
      const response = await CdhaService.getXetNghiemByCode(payload);
      if (onSuccess) (onSuccess(response.data));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  ketquaCdha: {},
  ketquaCls: {
    status: 'init'
  }
};

export const ketquaCdhaSlice = createSlice({
  name: "ketquaCdha",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    clearKetquaCls: (state) => {
      state.ketquaCls = {
        status: 'init'
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCdhaById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCdhaById.fulfilled, (state, action) => {
        state.loading = false;
        state.ketquaCdha = action.payload;
      })
      .addCase(getCdhaById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getXetNghiemByCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(getXetNghiemByCode.fulfilled, (state, action) => {
        state.loading = false;
        state.ketquaCls = action.payload;
      })
      .addCase(getXetNghiemByCode.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, clearKetquaCls } = ketquaCdhaSlice.actions;

export default ketquaCdhaSlice.reducer;
