import {
  GET_TRAINING_EXPENSES_API,
  UPDATE_TRAINING_EXPENSE_API,
  DELETE_TRAINING_EXPENSE_API,
  CREATE_TRAINING_EXPENSE_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const trainingExpenseService = {};

trainingExpenseService.Get = function () {
  return axiosInstance.get(`${GET_TRAINING_EXPENSES_API}`);
};
trainingExpenseService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_TRAINING_EXPENSE_API}`, data);
};
trainingExpenseService.Create = function (data) {
  return axiosInstance.post(`${CREATE_TRAINING_EXPENSE_API}`, data);
};
trainingExpenseService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_TRAINING_EXPENSE_API}?id=${data}`);
};

export default trainingExpenseService;
