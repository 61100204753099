import axiosInstance from "auth/FetchInterceptor";
import {
  BANG_PHU_CAP_GIAM_TRU_API,
  CHI_TIET_BANG_PHU_CAP_GIAM_TRU_API,
} from "constants/ApiConstant";
const QuanLyPhuCapGiamTruService = {};

QuanLyPhuCapGiamTruService.searchDsBangPhuCapGiamTru = function (data) {
  return axiosInstance.post(`${BANG_PHU_CAP_GIAM_TRU_API}/search`, data);
};

QuanLyPhuCapGiamTruService.getByIdDsBangPhuCapGiamTru = function (id) {
  return axiosInstance.get(`${BANG_PHU_CAP_GIAM_TRU_API}/get-by-id?id=${id}`);
};

QuanLyPhuCapGiamTruService.addDsBangPhuCapGiamTru = function (data) {
  return axiosInstance.post(`${BANG_PHU_CAP_GIAM_TRU_API}/create`, data);
};

QuanLyPhuCapGiamTruService.updateDsBangPhuCapGiamTru = function (data) {
  return axiosInstance.put(`${BANG_PHU_CAP_GIAM_TRU_API}/update`, data);
};

QuanLyPhuCapGiamTruService.deleteBangPhuCapGiamTru = function (id) {
  return axiosInstance.delete(`${BANG_PHU_CAP_GIAM_TRU_API}/delete?id=${id}`);
};

QuanLyPhuCapGiamTruService.copyBangPhuCapGiamTru = function (id) {
  return axiosInstance.get(
    `${BANG_PHU_CAP_GIAM_TRU_API}/copy-bang-cong?id=${id}`
  );
};

QuanLyPhuCapGiamTruService.changeStateDsBangPhuCapGiamTru = function (id) {
  return axiosInstance.put(
    `${BANG_PHU_CAP_GIAM_TRU_API}/chot-phu-cap-giam-tru?id=${id}`
  );
};

QuanLyPhuCapGiamTruService.searchChiTietBangPhuCapGiamTru = function (data) {
  return axiosInstance.post(
    `${CHI_TIET_BANG_PHU_CAP_GIAM_TRU_API}/search`,
    data
  );
};

QuanLyPhuCapGiamTruService.upSertChiTietBangPhuCapGiamTru = function (data) {
  return axiosInstance.post(`${CHI_TIET_BANG_PHU_CAP_GIAM_TRU_API}`, data);
};

QuanLyPhuCapGiamTruService.delNhanVienById = function (id) {
  return axiosInstance.delete(
    `${CHI_TIET_BANG_PHU_CAP_GIAM_TRU_API}/xoa-nhan-vien?id=${id}`
  );
};

QuanLyPhuCapGiamTruService.copyPhuCapGiamTruChiTiet = function (data) {
  return axiosInstance.post(`${CHI_TIET_BANG_PHU_CAP_GIAM_TRU_API}/copy`, data);
};

export default QuanLyPhuCapGiamTruService;
