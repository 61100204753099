import axiosInstance from "auth/FetchInterceptor";
import { DON_XIN_NGHI_API, NGHI_PHEP_NAM_API } from "constants/ApiConstant";
const QuanLyNghiService = {};

QuanLyNghiService.searchDonXinNghi = function (data) {
  return axiosInstance.post(`${DON_XIN_NGHI_API}/search`, data);
};

QuanLyNghiService.donXinNghiDetail = function (id) {
  return axiosInstance.get(`${DON_XIN_NGHI_API}/get-by-id?id=${id}`);
};

QuanLyNghiService.upSertDonXinNghi = function (data) {
  return axiosInstance.post(`${DON_XIN_NGHI_API}/upsert`, data);
};

QuanLyNghiService.deleteDonXinNghi = function (id) {
  return axiosInstance.delete(`${DON_XIN_NGHI_API}/delete?id=${id}`);
};

QuanLyNghiService.searchNghiPhepNam = function (data) {
  return axiosInstance.post(`${NGHI_PHEP_NAM_API}/search`, data);
};

QuanLyNghiService.addNewNghiPhepNam = function (data) {
  return axiosInstance.post(NGHI_PHEP_NAM_API, data);
};

QuanLyNghiService.updateNghiPhepNam = function (data) {
  return axiosInstance.put(NGHI_PHEP_NAM_API, data);
};

QuanLyNghiService.updateNghiPhepNamByIds = function (data) {
  return axiosInstance.put(`${NGHI_PHEP_NAM_API}/update-by-ids`, data);
};
export default QuanLyNghiService;
