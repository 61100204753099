import axiosInstance from "auth/FetchInterceptor";
import { BAOCAO_API } from "constants/ApiConstant";
const BaoCaoService = {};

BaoCaoService.getBangKeNhap = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bang-ke-nhap`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

BaoCaoService.getBangKeXuat = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bang-ke-xuat`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

BaoCaoService.getBaoCaoTonKho = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bao-cao-ton-kho`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};
BaoCaoService.getBCDoanhThu = function (data) {
  return axiosInstance.post(`${BAOCAO_API}/bao-cao-doanh-thu`, data, {
    baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
  });
};

export default BaoCaoService;
