import axiosInstance from "auth/FetchInterceptor";
import { DEXUATMUASAM_API,DANHMUC_API,TONGHOPDEXUATMUASAM_API } from "constants/ApiConstant";
const DeXuatMuaSamService = {};

// start dexuatmuasam

DeXuatMuaSamService.getbyid = function (id) {
  return axiosInstance.get(`${DEXUATMUASAM_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
DeXuatMuaSamService.create = function (data) {
  return axiosInstance.post(`${DEXUATMUASAM_API}`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
DeXuatMuaSamService.getall = function (data) {
  return axiosInstance.post(`${DEXUATMUASAM_API}/get-all`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
DeXuatMuaSamService.update = function (data) {
  
  return axiosInstance.put(`${DEXUATMUASAM_API}`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
DeXuatMuaSamService.delete = function (id) {
  return axiosInstance.delete(`${DEXUATMUASAM_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

// gửi yêu cầu xử lý đơn
DeXuatMuaSamService.putGuiYeuCauXuLyDon = function (data) {
  return axiosInstance.put(`${TONGHOPDEXUATMUASAM_API}/sale/de-xuat-mua-sam`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

DeXuatMuaSamService.getTrangThaiXuLyDon = function (data) {
  return axiosInstance.post(`${TONGHOPDEXUATMUASAM_API}/sale/check-trang-thai-de-xuat-mua-sam`,data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

DeXuatMuaSamService.putTrangThai = function (data) {
  return axiosInstance.put(`${DEXUATMUASAM_API}/trang-thai`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};
// end dexuatmuasam

// start chitietdexuatmuasam
DeXuatMuaSamService.createChiTiet = function (data) {
  return axiosInstance.post(`${DEXUATMUASAM_API}/chi-tiet`, data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

DeXuatMuaSamService.getDsChiTietByID = function (id) {
  return axiosInstance.get(`${DEXUATMUASAM_API}/chi-tiet?id=${id}`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

DeXuatMuaSamService.deleteDeXuatChiTiet = function (id) {
  return axiosInstance.delete(`${DEXUATMUASAM_API}/chi-tiet?id=${id}`, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};

DeXuatMuaSamService.getDmHangHoa = function (data) {
  return axiosInstance.post(`${DANHMUC_API}/get-dmhanghoa`,data, {
    baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
  });
};



// end chitietdexuatmuasam


export default DeXuatMuaSamService;
