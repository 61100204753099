import axiosInstance from "auth/FetchInterceptor";
import {
  THIETLAPTUYENDUNG_API,
  BOTIEUCHI_API,
  DEXUATTUYENDUNG_API,
  HOSOUNGVIEN_API,
  DANHGIAUNGVIEN_API,
} from "constants/ApiConstant";
const ThietLapTuyenDungService = {};

ThietLapTuyenDungService.GetAllThietLap = function (loai) {
  return axiosInstance.get(
    `${THIETLAPTUYENDUNG_API}/thiet-lap/get-all?loai=${loai}`
  );
};

ThietLapTuyenDungService.GetAllKhoaPhong = function (chiNhanhId) {
  return axiosInstance.get(
    `${THIETLAPTUYENDUNG_API}/thiet-lap/get-khoa-phong?ChiNhanhId=${chiNhanhId}`
  );
};

ThietLapTuyenDungService.upSertThietLap = function (data) {
  return axiosInstance.post(`${THIETLAPTUYENDUNG_API}/thiet-lap/up-sert`, data);
};

//------------------BO TIEU CHI TUYEN DUNG
ThietLapTuyenDungService.GetAllBoTieuChi = function (loai) {
  return axiosInstance.get(`${BOTIEUCHI_API}/bo-tieu-chi-danh-gia/get-all`);
};

ThietLapTuyenDungService.upSertBoTieuChi = function (data) {
  return axiosInstance.post(
    `${BOTIEUCHI_API}/bo-tieu-chi-danh-gia/up-sert`,
    data
  );
};

ThietLapTuyenDungService.GetAllBoTieuChiChiTiet = function (boTieuChiId) {
  return axiosInstance.get(
    `${BOTIEUCHI_API}/bo-tieu-chi-chi-tiet/get-all?BoTieuChiId=${boTieuChiId}`
  );
};

ThietLapTuyenDungService.upSertBoTieuChiChiTiet = function (data) {
  return axiosInstance.post(
    `${BOTIEUCHI_API}/bo-tieu-chi-chi-tiet/up-sert`,
    data
  );
};

ThietLapTuyenDungService.DeleteDanhGiaUngVienChiTiet = function (data) {
  return axiosInstance.post(
    `${BOTIEUCHI_API}/danh-gia-ung-vien/xoa-chi-tiet?id=${data}`
  );
};

//------------------DE XUAT TUYEN DUNG
ThietLapTuyenDungService.searchDeXuatTuyenDung = function (data) {
  return axiosInstance.post(`${DEXUATTUYENDUNG_API}/search`, data);
};

ThietLapTuyenDungService.GetDeXuatTuyenDung = function (maDeXuat) {
  return axiosInstance.get(
    `${DEXUATTUYENDUNG_API}/de-xuat-tuyen-dung/get-de-xuat?maDeXuat=${maDeXuat}`
  );
};
ThietLapTuyenDungService.GetAllDeXuatTuyenDung = function (trangThai) {
  return axiosInstance.get(
    `${DEXUATTUYENDUNG_API}/de-xuat-tuyen-dung/get-all?trangthai=${trangThai}`
  );
};
ThietLapTuyenDungService.XoaDeXuatTuyenDung = function (id) {
  return axiosInstance.delete(
    `${DEXUATTUYENDUNG_API}/de-xuat-tuyen-dung/xoa-de-xuat-tuyen-dung?Id=${id}`
  );
};

ThietLapTuyenDungService.upSertDeXuatTuyenDung = function (data) {
  return axiosInstance.post(
    `${DEXUATTUYENDUNG_API}/de-xuat-tuyen-dung/up-sert`,
    data
  );
};
ThietLapTuyenDungService.upTrangThai = function (data) {
  return axiosInstance.post(
    `${DEXUATTUYENDUNG_API}/de-xuat-tuyen-dung/up-trang-thai`,
    data
  );
};

//------------------HO SO TUYEN DUNG
ThietLapTuyenDungService.searchHoSoUngVien = function (data) {
  return axiosInstance.post(
    `${HOSOUNGVIEN_API}/ho-so-ung-vien/search-ho-so-ung-vien`,
    data
  );
};
ThietLapTuyenDungService.GetAllHoSoUngVien = function (trangthai) {
  return axiosInstance.get(
    `${HOSOUNGVIEN_API}/ho-so-ung-vien/get-all?trangthai=${trangthai}`
  );
};
ThietLapTuyenDungService.getAllHoSoThuViec = function (trangthai) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/get-all-thu-viec`
  );
};

ThietLapTuyenDungService.GetHoSoUngVien = function (Id) {
  return axiosInstance.get(
    `${HOSOUNGVIEN_API}/ho-so-ung-vien/get-ho-so?Id=${Id}`
  );
};
ThietLapTuyenDungService.XoaHoSoUngVien = function (Id) {
  return axiosInstance.delete(
    `${HOSOUNGVIEN_API}/ho-so-ung-vien/xoa-ho-so?Id=${Id}`
  );
};

ThietLapTuyenDungService.UpSertHoSoVtNhanVien = function (param) {
  return axiosInstance.get(
    `${HOSOUNGVIEN_API}/ho-so-ung-vien/up-sert-by-nhan-vien?maUngVienId=${param.maUngVienId}&userId=${param.userId}`
  );
};
ThietLapTuyenDungService.upSertHoSoUngVien = function (data) {
  return axiosInstance.post(`${HOSOUNGVIEN_API}/ho-so-ung-vien/up-sert`, data);
};
ThietLapTuyenDungService.upTrangThaiHoSoUngVien = function (data) {
  return axiosInstance.post(
    `${HOSOUNGVIEN_API}/ho-so-ung-vien/up-trang-thai`,
    data
  );
};

//------------------DANH GIA UNG VIẺN
ThietLapTuyenDungService.SearchDanhGiaUngVien = function (data) {
  return axiosInstance.post(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/search-danh-gia-ung-vien`,
    data
  );
};
ThietLapTuyenDungService.GetAllDanhGiaUngVien = function (loai) {
  return axiosInstance.get(`${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/get-all`);
};
ThietLapTuyenDungService.GetDanhGiaUngVien = function (id) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/get-danh-gia?MaDanhGiaId=${id}`
  );
};
ThietLapTuyenDungService.GetAllNhanVien = function (loai) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/get-all-nhan-vien`
  );
};

ThietLapTuyenDungService.upSertDanhGiaUngVien = function (data) {
  return axiosInstance.post(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/up-sert`,
    data
  );
};

ThietLapTuyenDungService.UpDateDanhGiaChiTiet = function (data) {
  return axiosInstance.post(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/up-sert-chi-tiet`,
    data
  );
};

ThietLapTuyenDungService.GetAllDanhGiaUngVienChiTiet = function (BoLoc) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/get-danh-gia-chi-tiet?BoTieuChiId=${BoLoc.BoTieuChiId}&MaDanhGiaId=${BoLoc.MaDanhGiaId}`
  );
};

ThietLapTuyenDungService.upSertDanhGiaUngVienChiTiet = function (data) {
  return axiosInstance.post(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/up-sert-chi-tiet`,
    data
  );
};
ThietLapTuyenDungService.DuyetTrungTuyen = function (BoLoc) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/duyet-trung-tuyen?MaHSUngVienId=${BoLoc.id}&TrangThai=${BoLoc.trangthai}`
  );
};
ThietLapTuyenDungService.XoaDanhGiaUngVien = function (BoLoc) {
  return axiosInstance.delete(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/xoa-danh-gia?Id=${BoLoc.id}`
  );
};
ThietLapTuyenDungService.XoaDanhGiaThuViec = function (BoLoc) {
  return axiosInstance.delete(
    `${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/xoa-danh-gia?Id=${BoLoc.id}`
  );
};
ThietLapTuyenDungService.DuyetChinhThuc = function (BoLoc) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/duyet-thu-viec?MaDanhGiaId=${BoLoc.id}&TrangThai=${BoLoc.trangthai}`
  );
};
ThietLapTuyenDungService.ChuyenHsThuViec = function (BoLoc) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-ung-vien/chuyen-thu-viec?maHSUngVienId=${BoLoc.maHSUngVienId}&userId=${BoLoc.userId}`
  );
};

//------------------DANH GIA THU VIEC
ThietLapTuyenDungService.SearchDanhGiaThuViec = function (data) {
  return axiosInstance.post(
    `${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/search-danh-gia-thu-viec`,
    data
  );
};

ThietLapTuyenDungService.GetAllDanhGiaThuViec = function (loai) {
  return axiosInstance.get(`${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/get-all`);
};

ThietLapTuyenDungService.GetDanhGiaThuViec = function (id) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/get-danh-gia-thu-viec?Id=${id}`
  );
};
ThietLapTuyenDungService.GetAllDanhGiaThuViecChiTiet = function (id) {
  return axiosInstance.get(
    `${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/get-danh-gia-thu-viec-chi-tiet?Id=${id}`
  );
};

ThietLapTuyenDungService.upSertDanhGiaThuViec = function (data) {
  return axiosInstance.post(
    `${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/up-sert`,
    data
  );
};

ThietLapTuyenDungService.UpDateDanhGiaThuViecChiTiet = function (data) {
  return axiosInstance.post(
    `${DANHGIAUNGVIEN_API}/danh-gia-thu-viec/up-sert-thu-viec-chi-tiet`,
    data
  );
};

export default ThietLapTuyenDungService;
