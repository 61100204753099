import axiosInstance from "auth/FetchInterceptor";
import { DICHVUTAINHACATEGORY_API } from "constants/ApiConstant";
const DichVuTaiNhaCategoryService = {};

DichVuTaiNhaCategoryService.searchGrid = function (data) {
  return axiosInstance.post(`${DICHVUTAINHACATEGORY_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
DichVuTaiNhaCategoryService.getAllDichVuTaiNhaCategory = function () {
  return axiosInstance.get(`${DICHVUTAINHACATEGORY_API}/get-all`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaCategoryService.getDichVuTaiNhaCategoryById = function (id) {
  return axiosInstance.get(`${DICHVUTAINHACATEGORY_API}/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaCategoryService.create = function (data) {
  return axiosInstance.post(DICHVUTAINHACATEGORY_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaCategoryService.update = function (data) {
  return axiosInstance.put(DICHVUTAINHACATEGORY_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaCategoryService.delete = function (id) {
  return axiosInstance.delete(`${DICHVUTAINHACATEGORY_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaCategoryService.changeStateHosiptal = function (data) {
  return axiosInstance.put(`${DICHVUTAINHACATEGORY_API}/change-state`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default DichVuTaiNhaCategoryService;
