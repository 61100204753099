import React from "react";
import { Button, Popconfirm } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { MediActionDelete, MediActionEdit } from "components/Custom";
const ActionButtons = ({
  record,
  editable,
  save,
  cancel,
  edit,
  handleDelete,
  extraButtons,
  disabled,
  disabledDelete = false,
  disabledEdit = false,
}) => {
  return editable ? (
    <>
      <Button
        onClick={() => save(record?.id || "initial")}
        className="mr-2"
        icon={<SaveOutlined />}
        shape="circle"
      />
      <MediActionDelete
        onClick={cancel}
        className="mr-2"
        // icon={<CloseOutlined />}
        // shape="circle"
      />
      {extraButtons && extraButtons()}
    </>
  ) : (
    <>
      <MediActionEdit
        disabled={disabled || disabledEdit}
        title={record.action === "initial" ? "Thêm thông tin" : "Sửa thông tin"}
        onClick={() => edit(record)}
        className="mr-2"
        // icon={record.action === "initial" ? <PlusOutlined /> : <EditOutlined />}
        // shape="circle"
      />
      {record.id && (
        <Popconfirm
          title="Bạn có muốn xóa không?"
          placement="topLeft"
          onConfirm={() => handleDelete(record.id)}
        >
          <MediActionDelete
            disabled={disabled || disabledDelete}
            title={"Xóa thông tin"}
            className="mr-2"
            // icon={<DeleteOutlined type="primary" />}
            // shape="circle"
          />
        </Popconfirm>
      )}
      {extraButtons && extraButtons()}
    </>
  );
};

export default ActionButtons;
