import {
  GET_TRAINING_CENTERS_API,
  UPDATE_TRAINING_CENTER_API,
  DELETE_TRAINING_CENTER_API,
  CREATE_TRAINING_CENTER_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const trainingCenterService = {};

trainingCenterService.Get = function () {
  return axiosInstance.get(`${GET_TRAINING_CENTERS_API}`);
};
trainingCenterService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_TRAINING_CENTER_API}`, data);
};
trainingCenterService.Create = function (data) {
  return axiosInstance.post(`${CREATE_TRAINING_CENTER_API}`, data);
};
trainingCenterService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_TRAINING_CENTER_API}?id=${data}`);
};

export default trainingCenterService;
