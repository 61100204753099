import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import employmentContractService from "services/EmploymentContractService";

// Async thunk to fetch HRM contract
export const getHRMContract = createAsyncThunk(
  "hrmContract/getHRMContract",
  async (_, { rejectWithValue }) => {
    try {
      const response = await employmentContractService.GetHRMContract();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  contractHRMList: [],
  error: null,
};

const hrmContractSlice = createSlice({
  name: "hrmContract",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHRMContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHRMContract.fulfilled, (state, action) => {
        state.loading = false;
        state.contractHRMList = action.payload;
      })
      .addCase(getHRMContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default hrmContractSlice.reducer;
