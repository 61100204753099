export const API_BASE_URL = "https://your-api-url.com/";

const PREFIX = "/en/api/v1.0";
export const UPLOAD_FILE = `${PREFIX}/Media/upload`;
export const DELETE_FILE = "/api/fileid";

export const GET_PROFILE = `${PREFIX}/User/profile`;
export const ACCOUNT_LOGIN = `${PREFIX}/User/login`;
export const SIGN_OUT = `${PREFIX}/User/logout`;
export const FORGOT_PASSWORD = `${PREFIX}/User/password/forgot-by-email`;
export const RESET_PASSWORD = `${PREFIX}/User/password/reset-by-email`;
export const CHANGE_PASSWORD = `${PREFIX}/User/password/change`;
export const SEARCH_BY_ROLES = `${PREFIX}/User/search-by-roles`;
export const REFRESH_TOKEN_API = `${PREFIX}/TokenManager/refresh-token`;

const RATING_API = `${PREFIX}/Rating`;
export const RATING_GETBYCODE_API = `${RATING_API}/get-by-code`;
export const RATING_SUBMIT_API = `${RATING_API}/submit`;
export const ESIGN_CHECKTHE_API = `${PREFIX}/Esign/check-the`;
export const ESIGN_SUBMIT_API = `${PREFIX}/Esign/submit`;

const REPORT_API = `${PREFIX}/Report`;
export const REPORT_FEEDBACK_FROM_CUSTOMER_API = `${REPORT_API}/feedback-from-customer`;
export const REPORT_CHART_HOSPITAL_API = `${REPORT_API}/chart-hospital`;
export const REPORT_CHART_ALL_HOSPITAL_API = `${REPORT_API}/chart-all-hospital`;
export const REPORT_CHART_KCB_ALL_HOSPITAL_API = `${REPORT_API}/chart-kcb-all-hospital`;
export const REPORT_CHART_KCB_HOSPITAL_API = `${REPORT_API}/chart-kcb-hospital`;

const CDHA_API = `${PREFIX}/KetQuaCdha`;
export const CDHA_VIEW_API = `${CDHA_API}/cdha`;
const XETNGHIEM_API = `${PREFIX}/KetQuaXetNghiem`;
export const XETNGHIEM_VIEW_API = `${XETNGHIEM_API}/xetnghiem`;

export const DEPARTMENT_API = `${PREFIX}/Department`;
export const DEPARTMENT_SEARCH_GRID_API = `${DEPARTMENT_API}/search-grid`;
export const DEPARTMENT_HOSPITALS_API = `${DEPARTMENT_API}/hospitals`;
const CATEGORY_API = `${PREFIX}/Category`;
export const GET_BY_JOB_POSITION_API = `${CATEGORY_API}/get-by-job-position`;
export const GET_BY_JOB_SPECCIALIZE_API = `${CATEGORY_API}/get-by-job-Specialize`;
export const GET_BY_JOB_TITLE_API = `${CATEGORY_API}/get-by-job-title`;
export const GET_BY_DEPARTMENT_API = `${CATEGORY_API}/get-by-department`;
export const GET_BY_WORK_STATUS_API = `${CATEGORY_API}/get-by-work-status`;
export const GET_BY_HIS_DEPARTMENT_API = `${CATEGORY_API}/get-by-his-department`;
export const GET_BY_DEPARTMENT_LEVEL_API = `${CATEGORY_API}/get-by-department-level`;
export const GET_BY_AUTH_PERMIT = `${CATEGORY_API}/get-by-auth-permit`;
export const GET_DEAPARTMENT_BY_BRANCH = `${CATEGORY_API}/department-by-branch`;
export const GET_SPECALIZE_BY_BRANCH = `${CATEGORY_API}/speciality`;
export const GET_PROVINCE_API = `${CATEGORY_API}/location/province`;
export const GET_DISTRICT_BY_PROVINCE_API = `${CATEGORY_API}/location/district-by-province`;
export const GET_WARD_BY_DISTRICT_API = `${CATEGORY_API}/location/ward-by-district`;
export const GET_TIME_SLOT = `${CATEGORY_API}/timeslot`;
export const GET_ALL_HOSPITAL_API = `${CATEGORY_API}/hospital`;
export const GET_ALL_BAGET_API = `${CATEGORY_API}/baget`;
export const GET_KHAMLAI_BAGET_API = `${CATEGORY_API}/baget-khamlai`;
export const GET_ALL_NATION_API = `${CATEGORY_API}/nation`;
export const GET_LOAI_CA_LAM_VIEC_CATEGORY_API = `${CATEGORY_API}/loai-ca`;
export const GET_SYMBOL_CATEGORY_API = `${CATEGORY_API}/symbol`;
export const GET_Reason_CATEGORY_API = `${CATEGORY_API}/reason`;
export const GET_ALL_EMPLOYEE_API = `${CATEGORY_API}/get-by-employee`;

export const EMPLOYEE_API = `${PREFIX}/Employee`;
export const EMPLOYEE_GRID_API = `${EMPLOYEE_API}/search-grid`;
export const EMPLOYEE_SEARCH_EMPLOYEE_API = `${EMPLOYEE_API}/search-employees`;
export const DETAIL_API = `${EMPLOYEE_API}/detail`;
export const CHANGE_STATE_EMPLOYEE_API = `${EMPLOYEE_API}/change-state`;
export const EXPORT_EXCEL_EMPLOYEE_API = `${EMPLOYEE_API}/export-excel`;
export const IMPORT_EXCEL_EMPLOYEE_API = `${EMPLOYEE_API}/import-excel`;
export const IMPORT_DSNV_EMPLOYEE_API = `${EMPLOYEE_API}/import-dsnv`;
export const IMPORT_HISTORY_API = `${EMPLOYEE_API}/import-history`;
export const IMPORT_EMPLOYEE_APPLIER_API = `${EMPLOYEE_API}/import-dsnv-applier`;

const USER_PERMISSION_API = `${PREFIX}/UserPermission`;
export const USER_BRANCH_API = `${USER_PERMISSION_API}/user-branchs`;
export const USER_SCOPE_API = `${USER_PERMISSION_API}/user-scopes`;
export const USER_ROLE_API = `${USER_PERMISSION_API}/user-roles`;

const ROLE_PERMIT_API = `${PREFIX}/RolePermission`;
export const ROLE_BY_SCOPE_API = `${ROLE_PERMIT_API}/role-by-scope`;
export const ROLE_DETAIL_API = `${ROLE_PERMIT_API}/role-detail`;
export const ADD_ROLE_API = `${ROLE_PERMIT_API}/add-role`;
export const UPDATE_ROLE_API = `${ROLE_PERMIT_API}/update-role`;
export const DELETE_ROLE_API = `${ROLE_PERMIT_API}/delete-role`;
export const UPDATE_ROLE_PERMIT_API = `${ROLE_PERMIT_API}/update-role-permit`;
export const ROLE_BY_PERMIT = `${ROLE_PERMIT_API}/role-by-permit`;

const SETTING_API = `${PREFIX}/Setting`;
export const SEARCH_BANNER_API = `${SETTING_API}/banner/search-grid`;
export const BANNER_API = `${SETTING_API}/banner`;
export const SETTING = `${SETTING_API}`;
export const DANH_GIA_API = `${PREFIX}/DanhGiaCLDV`;

export const POSTS_API = `${PREFIX}/Blog`;
export const SEARCH_POSTS_API = `${POSTS_API}/search-grid`;

export const KHACH_HANG_API = `${PREFIX}/KhachHang`;
export const HOSPITAL_MANAGER_API = `${PREFIX}/HospitalManagement`;
export const DOCTOR_MANAGEMENT_API = `${PREFIX}/DoctorManagement`;
export const APPOINTMENT_API = `${PREFIX}/Appointment`;
export const NOTIFICATION_API = `${PREFIX}/Notification`;
export const AMBULANCE_API = `${PREFIX}/Ambulance`;
export const DICHVUTAINHACATEGORY_API = `${PREFIX}/DichVuTaiNhaCategory`;
export const DICHVUTAINHA_API = `${PREFIX}/DichVuTaiNha`;

export const COMMENT_API = `${PREFIX}/Comment`;
export const SEARCH_COMMENT_API = `${COMMENT_API}/search-grid`;

// BHXH Category
const BHYT_CATEGORY_API = `${PREFIX}/BhytCategory`;
export const BHYT_SEARCH_DSNV = `${BHYT_CATEGORY_API}/search-dsnv`;
export const BHYT_IMPORT_DSNV = `${BHYT_CATEGORY_API}/import-dsnv`;
export const BHYT_APPLY_DSNV = `${BHYT_CATEGORY_API}/apply-dsnv`;

export const HRRECORD_API = `${PREFIX}/HRRecord`;
export const HRRECORD_ALL_API = `${HRRECORD_API}/hs-all-level`;

export const HRRECORD_HSC1_API = `${HRRECORD_API}/hsc1`;
export const CHANGE_STATE_HSC1_API = `${HRRECORD_API}/hsc1/change-state`;
export const HRRECORD_HSC2_API = `${HRRECORD_API}/hsc2`;
export const CHANGE_STATE_HSC2_API = `${HRRECORD_API}/hsc2/change-state`;
export const EXPORT_HSNV_API = `${HRRECORD_API}/export-hsnv`;
export const EXPORT_DSHSNV_API = `${HRRECORD_API}/export-dshsnv`;

// Nội bộ
export const SANG_KIEN_Y_TUONG = `${PREFIX}/SangKienYTuong`;

//Bang Cong
export const BANGCONG_API = `${PREFIX}/BangCong`;
export const CHI_TIET_BANG_CONG_API = `${BANGCONG_API}/chi-tiet`;
export const THIET_LAP_BANG_CONG_API = `${BANGCONG_API}/thiet-lap`;
export const BANG_CONG_TONG_HOP_API = `${BANGCONG_API}/tong-hop-nam`;

//QUAN LY CONG
export const QUAN_LY_CONG_API = `${PREFIX}/QuanLyCong`;
export const QUAN_LY_CONG_THIET_LAP_API = `${QUAN_LY_CONG_API}/ThietLap`;
export const QUAN_LY_CONG_CHAM_CONG_API = `${QUAN_LY_CONG_API}/BangChamCong`;

//Quan ly nghi
export const QUAN_LY_Nghi_API = `${PREFIX}/QuanLyNghi`;
export const DON_XIN_NGHI_API = `${QUAN_LY_Nghi_API}/DonXinNghi`;
export const NGHI_PHEP_NAM_API = `${QUAN_LY_Nghi_API}/NghiPhepNam`;

//Tuyển dụng
export const THIETLAPTUYENDUNG_API = `${PREFIX}/ThietLapTuyenDung`;
export const BOTIEUCHI_API = `${PREFIX}/BoTieuChi`;
export const DEXUATTUYENDUNG_API = `${PREFIX}/DeXuatTuyenDung`;
export const HOSOUNGVIEN_API = `${PREFIX}/HoSoUngVien`;
export const DANHGIAUNGVIEN_API = `${PREFIX}/DanhGiaUngVien`;
export const QUANLYDUAN_API = `${PREFIX}/QuanLyDuAn`;

// Công văn
export const QUAN_LY_VAN_BAN_API = `${PREFIX}/VanBan`;
export const CHI_TIET_CONG_VAN_API = `${QUAN_LY_VAN_BAN_API}/get-van-ban`;

//EmploymentContract
export const CONTRACT_API = `${PREFIX}/EmploymentContract`;
export const LIST_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/contract-list`;
export const UPDATE_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/update-contract`;
export const CREATE_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/create-contract`;
export const DELETE_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/delete-contract`;
export const STOP_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/stop-contract`;

export const DETAIL_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/chi-tiet`;
export const SYSTEM_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/thiet-lap`;
export const SETTING_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/contract-setting`;
export const TYPE_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/contract-type`;
export const HRM_EMPLOYMENT_CONTRACT_API = `${CONTRACT_API}/contract-hrm`;
export const UPDATE_SETTING_CONTRACT_API = `${CONTRACT_API}/update-contract-setting`;
export const CREATE_TYPE_CONTRACT_API = `${CONTRACT_API}/create-contract-type`;
export const UPDATE_TYPE_CONTRACT_API = `${CONTRACT_API}/update-contract-type`;
export const DELETE_TYPE_CONTRACT_API = `${CONTRACT_API}/delete-contract-type`;

export const GET_CONTRACT_BY_ID_API = `${CONTRACT_API}/get-contract-by-id`;
//Training
export const TRAINING_API = `${PREFIX}/Training`;
export const GET_TRAINING_METHODS_API = `${TRAINING_API}/get-training-methods`;
export const UPDATE_TRAINING_METHOD_API = `${TRAINING_API}/update-training-method`;
export const CREATE_TRAINING_METHOD_API = `${TRAINING_API}/create-training-method`;
export const DELETE_TRAINING_METHOD_API = `${TRAINING_API}/delete-training-method`;

export const GET_TRAINING_CENTERS_API = `${TRAINING_API}/get-training-centers`;
export const UPDATE_TRAINING_CENTER_API = `${TRAINING_API}/update-training-center`;
export const CREATE_TRAINING_CENTER_API = `${TRAINING_API}/create-training-center`;
export const DELETE_TRAINING_CENTER_API = `${TRAINING_API}/delete-training-center`;

export const GET_TRAINING_EXPENSES_API = `${TRAINING_API}/get-training-expenses`;
export const UPDATE_TRAINING_EXPENSE_API = `${TRAINING_API}/update-training-expense`;
export const CREATE_TRAINING_EXPENSE_API = `${TRAINING_API}/create-training-expense`;
export const DELETE_TRAINING_EXPENSE_API = `${TRAINING_API}/delete-training-expense`;

export const GET_TRAINING_PROGRAMS_API = `${TRAINING_API}/get-training-programs`;
export const GET_TRAINING_PROGRAM_BY_ID_API = `${TRAINING_API}/get-training-program-by-id`;
export const UPDATE_TRAINING_PROGRAM_API = `${TRAINING_API}/update-training-program`;
export const CREATE_TRAINING_PROGRAM_API = `${TRAINING_API}/create-training-program`;
export const DELETE_TRAINING_PROGRAM_API = `${TRAINING_API}/delete-training-program`;

export const GET_TRAINING_EMPLOYEE_API = `${TRAINING_API}/get-training-employees`;
export const GET_ALL_TRAINING_EMPLOYEE_API = `${TRAINING_API}/get-all-training-employees`;
export const UPDATE_TRAINING_EMPLOYEE_API = `${TRAINING_API}/update-training-employee`;

//Signature Process
export const SIGNATURE_PROCESS_API = `/api/SignatureProcess`;
export const GET_DOCUMENT_TYPES_API = `${SIGNATURE_PROCESS_API}/getall-document-types`;
export const GET_SIGN_POSITIONS_API = `${SIGNATURE_PROCESS_API}/getall-sign-positions`;
export const GET_SIGN_REPORT_API = `${SIGNATURE_PROCESS_API}/get-sign-report`;
//OVERTIME
export const OVERTIME_API = `${PREFIX}/OverTime`;
export const GET_OVERTIME_API = `${OVERTIME_API}/get-ot`;
export const GET_OVERTIME_BY_ID_API = `${OVERTIME_API}/get-ot-by-id`;
export const UPDATE_OVERTIME_API = `${OVERTIME_API}/update-ot`;
export const CREATE_OVERTIME_API = `${OVERTIME_API}/create-ot`;
export const DELETE_OVERTIME_API = `${OVERTIME_API}/delete-ot`;
//Meal Allowance
export const MEAL_ALLOWANCE_API = `${PREFIX}/MealAllowance`;
export const GET_MEAL_ALLOWANCE_API = `${MEAL_ALLOWANCE_API}/get-ma`;
export const GET_MEAL_ALLOWANCE_BY_ID_API = `${MEAL_ALLOWANCE_API}/get-ma-by-id`;
export const UPDATE_MEAL_ALLOWANCE_API = `${MEAL_ALLOWANCE_API}/update-ma`;
export const CREATE_MEAL_ALLOWANCE_API = `${MEAL_ALLOWANCE_API}/create-ma`;
export const DELETE_MEAL_ALLOWANCE_API = `${MEAL_ALLOWANCE_API}/delete-ma`;

export const CREATE_MA_DETAIL_API = `${MEAL_ALLOWANCE_API}/create-ma-detail`;
export const GET_MA_DETAIL_API = `${MEAL_ALLOWANCE_API}/get-ma-detail`;
export const UPDATE_MA_DETAIL_API = `${MEAL_ALLOWANCE_API}/update-ma-detail`;
export const DELETE_MA_DETAIL_API = `${MEAL_ALLOWANCE_API}/delete-ma-detail`;
export const UPDATE_MA_TOTAL_EMPLOYEE_API = `${MEAL_ALLOWANCE_API}/update-ma-total-employee`;
// Bang luong
// -- Thiet lap luong
export const QUAN_LY_LUONG_API = `${PREFIX}/QuanLyLuong`;
export const THIETLAP_LUONG_API = `${PREFIX}/ThietLapLuong`;
export const BANG_LUONG_API = `${QUAN_LY_LUONG_API}/BangLuong`;

export const GET_ALL_THIETLAP_LUONG_API = `${THIETLAP_LUONG_API}/danh-sach`;
// Common
// -- Attachment
export const ATTACHMENT_API = `/api/Attachment`;
export const UPLOAD_ATTACHMENT_API = `${ATTACHMENT_API}/upload`;
export const GET_BY_OBJECT_ATTACHMENT_API = `${ATTACHMENT_API}/get-by-object-id`;
// -- Signature Process
export const INTERNAL_API = `${PREFIX}/Internal/update-signature-process`;
// --Notification
export const ERP_NOTIFICATION_API = `/api/ErpNotification`;
export const PRINTER_API = `api/CreatePrint`;

//Quy che- Van ban
export const REGULATION_API = `${PREFIX}/RegulationAndDocument`;
export const GET_REGULATION_SETTING_API = `${REGULATION_API}/get-regulation-setting`;
export const UPDATE_REGULATION_SETTING_API = `${REGULATION_API}/update-regulation-setting`;
export const CREATE_REGULATION_SETTING_API = `${REGULATION_API}/create-regulation-setting`;
export const DELETE_REGULATION_SETTING_API = `${REGULATION_API}/delete-regulation-setting`;

export const GET_DOCUMENT_SETTING_API = `${REGULATION_API}/get-document-setting`;
export const UPDATE_DOCUMENT_SETTING_API = `${REGULATION_API}/update-document-setting`;
export const CREATE_DOCUMENT_SETTING_API = `${REGULATION_API}/create-document-setting`;
export const DELETE_DOCUMENT_SETTING_API = `${REGULATION_API}/delete-document-setting`;
export const DELETE_REGULATION_GROUP_API = `${REGULATION_API}/delete-regulation-group`;

export const GET_REGULATION_MANAGE_API = `${REGULATION_API}/get-regulation-manage`;
export const GET_REGULATION_MANAGE_BY_ID_API = `${REGULATION_API}/get-regulation-manage-by-id`;
export const UPDATE_REGULATION_MANAGE_API = `${REGULATION_API}/update-regulation-manage`;
export const CREATE_REGULATION_MANAGE_API = `${REGULATION_API}/create-regulation-manage`;
export const DELETE_REGULATION_MANAGE_API = `${REGULATION_API}/delete-regulation-manage`;

export const GET_DOCUMENT_MANAGE_API = `${REGULATION_API}/get-document-manage`;
export const GET_DOCUMENT_MANAGE_BY_ID_API = `${REGULATION_API}/get-document-manage-by-id`;
export const UPDATE_DOCUMENT_MANAGE_API = `${REGULATION_API}/update-document-manage`;
export const CREATE_DOCUMENT_MANAGE_API = `${REGULATION_API}/create-document-manage`;
export const DELETE_DOCUMENT_MANAGE_API = `${REGULATION_API}/delete-document-manage`;

// ho so - tai lieu
export const GET_HOSO_API = `${REGULATION_API}/get-ho-so`;
export const GET_HOSO_BY_ID_API = `${REGULATION_API}/get-ho-so-by-id`;
export const UPDATE_HOSO_API = `${REGULATION_API}/update-ho-so`;
export const CREATE_HOSO_API = `${REGULATION_API}/create-ho-so`;
export const DELETE_HOSO_API = `${REGULATION_API}/delete-ho-so`;

//Bang Phu cap Giam tru
export const BANG_PHU_CAP_GIAM_TRU_API = `${PREFIX}/QuanLyPhuCapGiamTru`;
export const CHI_TIET_BANG_PHU_CAP_GIAM_TRU_API = `${BANG_PHU_CAP_GIAM_TRU_API}/chi-tiet`;

export const THIETLAPDMKSK_API = `${PREFIX}/ThietLapDmKSK`;
export const KHAMSUCKHOEDOAN_API = `${PREFIX}/DoanKhamSucKhoe`;

//Ghi Nhan Yeu Cau

export const GHI_NHAN_API = `${PREFIX}/GhiNhanYeuCau`;
export const GET_GHI_NHAN_BY_ID_API = `${GHI_NHAN_API}/get-by-id`;
export const GET_HISTORY_GHI_NHAN_BY_ID_API = `${GHI_NHAN_API}/get-history-by-id`;
export const GET_ALL_GHI_NHAN_API = `${GHI_NHAN_API}/get-all`;
export const CREATE_GHI_NHAN_API = `${GHI_NHAN_API}/create-ghi-nhan`;
export const UPDATE_GHI_NHAN_API = `${GHI_NHAN_API}/update-ghi-nhan`;
export const GET_CATE_GHI_NHAN_API = `${GHI_NHAN_API}/get-category`;

//SALE

export const NHAPXUAT_API = `${PREFIX}/NhapXuat`;
export const NHAPKHO_API = `${PREFIX}/NhapKho`;
export const XUATKHO_API = `${PREFIX}/XuatKho`;
export const DANHMUC_API = `${PREFIX}/DanhMuc`;
export const BANHANG_API = `${PREFIX}/BanHang`;
export const TRAHANG_API = `${PREFIX}/TraHang`;
export const THUCHI_API = `${PREFIX}/ThuChi`;
export const TRACUU_API = `${PREFIX}/TraCuu`;
export const BAOCAO_API = `${PREFIX}/BaoCao`;
export const THIETLAPHETHONG_API = `${PREFIX}/ThietLapHeThong`;
export const DEXUATMUASAM_API = `${PREFIX}/DeXuatMuaSam`;
export const TONGHOPDEXUATMUASAM_API = `${PREFIX}/TongHopDeXuatMuaSam`;
export const SALECHANGEHISTORY_API = `${PREFIX}/SaleChangeHistory`;

//Notifications

export const THONG_BAO_API = `/api/ErpNotification`;
export const GET_THONG_BAO_API = `${THONG_BAO_API}/get-all-noti`;
export const READALL_THONG_BAO_API = `${THONG_BAO_API}/read-all`;
export const DELETEALL_THONG_BAO_API = `${THONG_BAO_API}/delete-all`;
export const DELETEBYID_THONG_BAO_API = `${THONG_BAO_API}/delete-by-id`;
export const MARKREAD_THONG_BAO_API = `${THONG_BAO_API}/mark-read`;

// EmployeeTransfer
export const EMPLOYEE_TRANSFER_API = `${PREFIX}/EmployeeTransfer`;

//Partner

export const PARTNER_API = `${PREFIX}/Partner`;
export const GET_PARTNERS_API = `${PARTNER_API}/get-partners`;
export const GET_PARTNER_BY_ID_API = `${PARTNER_API}/get-partner-by-id`;
export const UPDATE_PARTNER_API = `${PARTNER_API}/update-partner`;
export const CREATE_PARTNER_API = `${PARTNER_API}/create-partner`;
export const DELETE_PARTNER_API = `${PARTNER_API}/delete-partner`;
