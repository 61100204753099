import axiosInstance from "auth/FetchInterceptor";
import { BANG_LUONG_API } from "constants/ApiConstant";
const QuanLyLuongService = {};

QuanLyLuongService.gridSearchBangLuong = function (data) {
  return axiosInstance.post(`${BANG_LUONG_API}/search`, data);
};

QuanLyLuongService.getBangLuongById = function (id) {
  return axiosInstance.get(`${BANG_LUONG_API}/get-by-id?id=${id}`);
};

QuanLyLuongService.create = function (data) {
  return axiosInstance.post(`${BANG_LUONG_API}/create`, data);
};

QuanLyLuongService.update = function (data) {
  return axiosInstance.put(`${BANG_LUONG_API}/update`, data);
};

QuanLyLuongService.delete = function (id) {
  return axiosInstance.delete(`${BANG_LUONG_API}/delete?id=${id}`);
};
QuanLyLuongService.changeStateBangLuong = function (id) {
  return axiosInstance.put(`${BANG_LUONG_API}/change-state?id=${id}`);
};
QuanLyLuongService.gridSearchBangLuongNhanVien = function (data) {
  return axiosInstance.post(`${BANG_LUONG_API}/search-luong-nhan-vien`, data);
};

QuanLyLuongService.gridSearchBangCongLuong = function (data) {
  return axiosInstance.post(`${BANG_LUONG_API}/search-bang-cong`, data);
};
QuanLyLuongService.duyetBangLuong = function (id) {
  return axiosInstance.put(`${BANG_LUONG_API}/duyet-bang-luong?id=${id}`);
};

QuanLyLuongService.gridSearchBangPhuCapGiamTruLuong = function (data) {
  return axiosInstance.post(
    `${BANG_LUONG_API}/search-bang-phu-cap-giam-tru`,
    data
  );
};

QuanLyLuongService.updateBangLuongNhanVien = function (data) {
  return axiosInstance.put(
    `${BANG_LUONG_API}/update-bang-luong-nhan-vien`,
    data
  );
};

QuanLyLuongService.rebuildResultLuongNhanVien = function (data) {
  return axiosInstance.post(`${BANG_LUONG_API}/rebuild-result-luong`, data);
};
QuanLyLuongService.export = function (id) {
  return axiosInstance.get(`${BANG_LUONG_API}/chi-tiet/export?id=${id}`);
};
export default QuanLyLuongService;
