import {
  GET_OVERTIME_API,
  UPDATE_OVERTIME_API,
  CREATE_OVERTIME_API,
  DELETE_OVERTIME_API,
  GET_OVERTIME_BY_ID_API,
} from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const overTimeService = {};

overTimeService.Get = function (data) {
  return axiosInstance.post(`${GET_OVERTIME_API}`, data);
};
overTimeService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_OVERTIME_API}`, data);
};
overTimeService.Create = function (data) {
  return axiosInstance.post(`${CREATE_OVERTIME_API}`, data);
};
overTimeService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_OVERTIME_API}?id=${data}`);
};
overTimeService.GetById = function (data) {
  return axiosInstance.get(`${GET_OVERTIME_BY_ID_API}?id=${data}`);
};
export default overTimeService;
