import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import SettingService from "services/SettingService";
import { initHoSo } from "views/app-views/hrm/hr-records/c1";

export const getAllBanner = createAsyncThunk(
  "setting/getAllBanner",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.searchGridBanner(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getBannerById = createAsyncThunk(
  "setting/getBannerById",
  async (Id, { rejectWithValue }) => {
    try {
      const response = await SettingService.bannerById(Id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addNewBanner = createAsyncThunk(
  "setting/addNewBanner",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.createBanner(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateBannerApi = createAsyncThunk(
  "setting/updateBannerApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.updateBanner(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteBannerApi = createAsyncThunk(
  "setting/deleteBannerApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await SettingService.deleteBanner(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getAllSetting = createAsyncThunk(
  "setting/getAllSetting",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.getAllSetting();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateSetting = createAsyncThunk(
  "setting/updateSetting",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.updateSetting(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getThietLapDanhGia = createAsyncThunk(
  "setting/getThietLapDanhGia",
  async (type, { rejectWithValue }) => {
    try {
      const response = await SettingService.getThietLapDanhGia(type);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getThietLapDanhGiaDetail = createAsyncThunk(
  "setting/getThietLapDanhGiaDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await SettingService.getThietLapDanhGiaById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpSertThietLapDanhGia = createAsyncThunk(
  "setting/UpSertThietLapDanhGia",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.UpsertThietLapDanhGia(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const DeleteThietLapDanhGia = createAsyncThunk(
  "setting/DeleteThietLapDanhGia",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await SettingService.deleteThietLapDanhGia(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetAllRating = createAsyncThunk(
  "setting/GetAllRating",
  async (id, { rejectWithValue }) => {
    try {
      const response = await SettingService.getRating(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpSertRating = createAsyncThunk(
  "setting/UpSertRating",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.upSertRating(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const DeleteRating = createAsyncThunk(
  "setting/DeleteRating",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await SettingService.deleteRating(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  bannerList: [],
  bannerDetail: {},
  settingDetail: {},
  danhGiaList: [],
  danhGiaDetail: {},
  ratingList: [],
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.bannerList = action.payload;
      })
      .addCase(getAllBanner.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBannerById.pending, (state) => {
        state.loading = true;
        state.bannerDetail = {};
      })
      .addCase(getBannerById.fulfilled, (state, action) => {
        state.loading = false;
        state.bannerDetail = action.payload;
      })
      .addCase(getBannerById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewBanner.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewBanner.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateBannerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBannerApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateBannerApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBannerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBannerApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBannerApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.settingDetail = action.payload;
      })
      .addCase(getAllSetting.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSetting.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSetting.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getThietLapDanhGia.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThietLapDanhGia.fulfilled, (state, action) => {
        state.loading = false;
        state.danhGiaList = action.payload;
      })
      .addCase(getThietLapDanhGia.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getThietLapDanhGiaDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThietLapDanhGiaDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.danhGiaDetail = action.payload;
      })
      .addCase(getThietLapDanhGiaDetail.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(UpSertThietLapDanhGia.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpSertThietLapDanhGia.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpSertThietLapDanhGia.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(GetAllRating.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllRating.fulfilled, (state, action) => {
        state.loading = false;
        state.ratingList = [initHoSo, ...action.payload];
      })
      .addCase(GetAllRating.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setRoleDetail } = settingSlice.actions;

export default settingSlice.reducer;
