import axiosInstance from "auth/FetchInterceptor";

const { ATTACHMENT_API, UPLOAD_ATTACHMENT_API, GET_BY_OBJECT_ATTACHMENT_API } = require("constants/ApiConstant");

const AttachmentService = {};

AttachmentService.upload = function (formData) {
  return axiosInstance.post(UPLOAD_ATTACHMENT_API, formData, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    ContentType: "multipart/form-data",
  });
};

AttachmentService.getByObjectId = function (data) {
  return axiosInstance.get(`${GET_BY_OBJECT_ATTACHMENT_API}?objectId=${data.objectId}&objectType=${data.objectType}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};


AttachmentService.create = function (data) {
  return axiosInstance.post(ATTACHMENT_API, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};
AttachmentService.update = function (data) {
  return axiosInstance.put(ATTACHMENT_API, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};  
AttachmentService.delete = function (id) {
  return axiosInstance.delete(`${ATTACHMENT_API}/id?id=${id}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

// ThietLapLuongService.getCommentById = function (id) {
//   return axiosInstance.get(`${COMMENT_API}/get-by-id?id=${id}`, {
//     baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
//   });
// };

// ThietLapLuongService.create = function (data) {
//   return axiosInstance.post(COMMENT_API, data, {
//     baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
//   });
// };

// CommentService.update = function (data) {
//   return axiosInstance.put(`${COMMENT_API}/update`, data, {
//     baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
//   });
// };

// CommentService.delete = function (id) {
//   return axiosInstance.delete(`${COMMENT_API}?id=${id}`, {
//     baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
//   });
// };

export default AttachmentService;
