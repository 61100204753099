import React from 'react';

const ExportSvgBtn = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
        {...props}
    >
        <g
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            clipPath="url(#a)"
        >
            <path d="M7 13.563A6.562 6.562 0 1 0 7 .438a6.562 6.562 0 0 0 0 13.124ZM7 3.938v6.124M3.938 7h6.124" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="currentColor" d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default ExportSvgBtn;
