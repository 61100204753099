import React from 'react';

const SaveBtn = props => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 0.5H12L16 4.5V14C16 14.3978 15.842 14.7794 15.5607 15.0607C15.2794 15.342 14.8978 15.5 14.5 15.5H2.5C2.10218 15.5 1.72064 15.342 1.43934 15.0607C1.15804 14.7794 1 14.3978 1 14V2C1 1.60218 1.15804 1.22064 1.43934 0.93934C1.72064 0.658035 2.10218 0.5 2.5 0.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 2.5V5.5H4V2.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 13.5V9.5H4V13.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default SaveBtn
