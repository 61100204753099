import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import employmentContractService from "services/EmploymentContractService";
import { cloneDeep } from "lodash";
// Async thunk to fetch contract settings
export const getSettingContract = createAsyncThunk(
  "contractSettings/getSettingContract",
  async (_, { rejectWithValue }) => {
    try {
      const response = await employmentContractService.GetSettingContract();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateSettingContract = createAsyncThunk(
  "contractSettings/updateSettingContract",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await employmentContractService.UpdateSettingContract(
        data
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  contractSetting: null,
};

const contractSettingsSlice = createSlice({
  name: "contractSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettingContract.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSettingContract.fulfilled, (state, action) => {
        state.loading = false;
        state.contractSetting = action.payload;
      })
      .addCase(getSettingContract.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSettingContract.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSettingContract.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateSettingContract.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default contractSettingsSlice.reducer;
