import React, { Fragment } from 'react';

const ExportSvgBtn = props => (
    <Fragment>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={14}
            fill="none"
            {...props}
        >
            <path
                fill="currentColor"
                d="M13.667 13.333h-12a1 1 0 0 1-1-1v-12a.333.333 0 0 0-.667 0v12A1.669 1.669 0 0 0 1.667 14h12a.333.333 0 1 0 0-.667Z"
            />
            <path
                fill="currentColor"
                d="M5.862 8.138a.667.667 0 0 0 .943 0l4.264-4.264 1.695 1.695a.333.333 0 0 0 .566-.192l.667-5a.334.334 0 0 0-.374-.374l-5 .667a.333.333 0 0 0-.192.566l1.695 1.696-4.264 4.263a.667.667 0 0 0 0 .943Z"
            />
        </svg>
    </Fragment>
);

export default ExportSvgBtn;
