import axiosInstance from "auth/FetchInterceptor";

import {
  SIGNATURE_PROCESS_API,
  GET_DOCUMENT_TYPES_API,
  GET_SIGN_POSITIONS_API,
  GET_SIGN_REPORT_API,
} from "constants/ApiConstant";

const SignatureProcessService = {};

SignatureProcessService.getAllDocumentTypes = function (data) {
  return axiosInstance.get(`${GET_DOCUMENT_TYPES_API}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.getAllSignPositions = function (data) {
  return axiosInstance.get(`${GET_SIGN_POSITIONS_API}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.searchGrid = function (data) {
  return axiosInstance.get(`${SIGNATURE_PROCESS_API}/search-grid`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.getAllSignatureProcess = function (data) {
  return axiosInstance.post(`${SIGNATURE_PROCESS_API}/search-process`, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.getSignatureProcessById = function (id) {
  return axiosInstance.get(`${SIGNATURE_PROCESS_API}/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.getSignedDocument = function (data) {
  return axiosInstance.post(
    `${SIGNATURE_PROCESS_API}/get-signed-document`,
    data,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

SignatureProcessService.create = function (data) {
  return axiosInstance.post(`${SIGNATURE_PROCESS_API}/create`, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.update = function (data) {
  return axiosInstance.put(`${SIGNATURE_PROCESS_API}/update`, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.delete = function (id) {
  return axiosInstance.delete(`${SIGNATURE_PROCESS_API}/delete?id=${id}`, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.createSignedDocument = function (data) {
  return axiosInstance.post(
    `${SIGNATURE_PROCESS_API}/create-signed-document`,
    data,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

SignatureProcessService.reloadSignedDocument = function (data) {
  return axiosInstance.post(
    `${SIGNATURE_PROCESS_API}/reload-signed-document`,
    data,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

SignatureProcessService.updateSignedDocument = function (data) {
  return axiosInstance.put(
    `${SIGNATURE_PROCESS_API}/update-signed-document`,
    data,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

SignatureProcessService.deleteSignedDocument = function (data) {
  return axiosInstance.put(
    `${SIGNATURE_PROCESS_API}/delete-signed-document`,
    data,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

SignatureProcessService.GetSignReport = function (data) {
  return axiosInstance.post(`${GET_SIGN_REPORT_API}`, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.signRemind = function (data) {
  return axiosInstance.post(`${SIGNATURE_PROCESS_API}/sign-remind`, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.signReject = function (data) {
  return axiosInstance.post(`${SIGNATURE_PROCESS_API}/sign-reject`, data, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

SignatureProcessService.createSignPosition = function (data) {
  return axiosInstance.post(
    `${SIGNATURE_PROCESS_API}/create-sign-position`,
    data,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

SignatureProcessService.updateSignPosition = function (data) {
  return axiosInstance.post(
    `${SIGNATURE_PROCESS_API}/update-sign-position`,
    data,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

SignatureProcessService.deleteSignPosition = function (data) {
  return axiosInstance.delete(
    `${SIGNATURE_PROCESS_API}/delete-sign-position?id=${data}`,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

SignatureProcessService.initDocumentType = function () {
  return axiosInstance.post(
    `${SIGNATURE_PROCESS_API}/init-document-types`,
    null,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

export default SignatureProcessService;
