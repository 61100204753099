import axiosInstance from "auth/FetchInterceptor";
import { DICHVUTAINHA_API } from "constants/ApiConstant";
import { HOSPITAL_MANAGER_API } from "constants/ApiConstant";
import { DOCTOR_MANAGEMENT_API } from "constants/ApiConstant";
const DichVuTaiNhaService = {};

DichVuTaiNhaService.getAllHospital = function () {
  return axiosInstance.get(`${HOSPITAL_MANAGER_API}/get-all`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaService.getAllDoctor = function () {
  return axiosInstance.get(`${DOCTOR_MANAGEMENT_API}/mobile/get-all`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaService.searchGrid = function (data) {
  return axiosInstance.post(`${DICHVUTAINHA_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaService.getDichVuTaiNhaById = function (id) {
  return axiosInstance.get(`${DICHVUTAINHA_API}/mobile/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaService.create = function (data) {
  return axiosInstance.post(DICHVUTAINHA_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaService.update = function (data) {
  return axiosInstance.put(`${DICHVUTAINHA_API}/update`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaService.delete = function (id) {
  return axiosInstance.delete(`${DICHVUTAINHA_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

DichVuTaiNhaService.changeStateDichVuTaiNha = function (data) {
  return axiosInstance.put(`${DICHVUTAINHA_API}/change-state`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default DichVuTaiNhaService;
