import axiosInstance from "auth/FetchInterceptor";
import { SALECHANGEHISTORY_API } from "constants/ApiConstant";
const SaleChangeHistoryService = {};

SaleChangeHistoryService.getSaleChangeHistory = function (data) {
  return axiosInstance.get(
    `${SALECHANGEHISTORY_API}/get-all?id=${data.id}&&ma=${data.ma}&&type=${data.type}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
export default SaleChangeHistoryService;
