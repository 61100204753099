import axiosInstance from "auth/FetchInterceptor";
import {
  DANH_GIA_API,
  DOCTOR_MANAGEMENT_API,
  REPORT_CHART_ALL_HOSPITAL_API,
  REPORT_CHART_HOSPITAL_API,
  REPORT_CHART_KCB_ALL_HOSPITAL_API,
  REPORT_CHART_KCB_HOSPITAL_API,
  REPORT_FEEDBACK_FROM_CUSTOMER_API,
} from "constants/ApiConstant";
const ReportService = {};

ReportService.getFeedBackFromCustomer = function (data) {
  return axiosInstance.post(REPORT_FEEDBACK_FROM_CUSTOMER_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.searchDGCL = function (data) {
  return axiosInstance.post(`${DANH_GIA_API}/search`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.getFeedBackFromCustomerExport = function (data) {
  return axiosInstance.post(
    `${REPORT_FEEDBACK_FROM_CUSTOMER_API}/export`,
    data,
    {
      baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    }
  );
};
ReportService.getReportThisHospital = function (data) {
  return axiosInstance.post(REPORT_CHART_HOSPITAL_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.getReportAllHospital = function (data) {
  return axiosInstance.post(REPORT_CHART_ALL_HOSPITAL_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.getReportKcbAllHospital = async function (data) {
  return await axiosInstance.post(REPORT_CHART_KCB_ALL_HOSPITAL_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};
ReportService.getReportKcbHospital = async function (data) {
  return await axiosInstance.post(REPORT_CHART_KCB_HOSPITAL_API, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

ReportService.getAllDoctor = function () {
  return axiosInstance.get(`${DOCTOR_MANAGEMENT_API}/mobile/get-all`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default ReportService;
